import { Box, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';

const otherAtt = { directory: '', webkitdirectory: '' };

const ProjectAddCases = () => {
  const { setLoading, setAlertMessage, setLoadingText } = useContext(CommonContext);
  const { openAddCasesDialog, setOpenAddCasesDialog, addCasesToProject } = useContext(ProjectsContext);

  const fileUploader = useRef<any>();

  const [files, setFiles] = useState<any[]>([]);

  const onSubmit = async (_e: any) => {
    handleClose();
    setLoading(true);
    setLoadingText('Adding cases to project');
    const id = uuidv4();
    const newCases = {
      files: files,
    };
    await addCasesToProject(newCases, setAlertMessage, (message: string) => {
      setLoadingText(message);
    });
    setLoadingText('');
    setLoading(false);
  };

  const getSelectedFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const files = [];
    const inputFieldFileList = (event.target && event.target.files) || [];
    for (let i = 0; i < inputFieldFileList.length; i++) {
      files.push(inputFieldFileList[i]);
    }
    setFiles(files);
  };

  const handleClose = () => {
    setOpenAddCasesDialog(false);
  };

  return (
    <Dialog
      open={openAddCasesDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Add cases to project</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <InputLabel
            id="demo-simple-select-filled-label"
            sx={{
              fontSize: '13px',
            }}
          >
            Files*
          </InputLabel>
          <Box mt={0.8} ml={1}>
            <Button variant="outlined" component="label">
              Select Folder
              <input
                onChange={getSelectedFiles}
                ref={fileUploader}
                type="file"
                id="file"
                multiple
                hidden
                {...otherAtt}
              />
            </Button>
            {files.length > 0 && <span> {files.length} files selected</span>}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectAddCases;
