import { Box, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';

const otherAtt = { directory: '', webkitdirectory: '' };

const ProjectAdd = () => {
  const { setLoading, setLoadingText, setAlertMessage } = useContext(CommonContext);
  const { openAddDialog, setOpenAddDialog, createProject } = useContext(ProjectsContext);

  const fileUploader = useRef<any>();

  const [files, setFiles] = useState<any[]>([]);
  const [project, setProject] = useState({
    name: '',
    info: '',
  });

  const onSubmit = async (_e: any) => {
    if (!project.name || files.length === 0) {
      setAlertMessage('Please input the required fields!');
      return;
    }
    handleClose();
    setLoading(true);
    setLoadingText('Creating project');
    const id = uuidv4();
    const newProject = {
      id: id,
      name: project.name,
      info: project.info,
      files: files,
    };
    await createProject(newProject, setAlertMessage, (message: string) => {
      setLoadingText(message);
    });
    setLoadingText('');
    setLoading(false);
  };

  const onChange = (e: any) => {
    setProject({ ...project, [e.target.name]: e.target.value });
  };

  const getSelectedFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const files = [];
    const inputFieldFileList = (event.target && event.target.files) || [];
    for (let i = 0; i < inputFieldFileList.length; i++) {
      files.push(inputFieldFileList[i]);
    }
    setFiles(files);
  };

  const handleClose = () => {
    setOpenAddDialog(false);
  };

  return (
    <Dialog
      open={openAddDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">New Project</DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          name="name"
          fullWidth
          required
          onChange={onChange}
        />
        <TextField
          variant="standard"
          margin="dense"
          id="info"
          label="Description"
          type="text"
          name="info"
          fullWidth
          onChange={onChange}
        />
        <Box mt={2}>
          <InputLabel
            id="demo-simple-select-filled-label"
            sx={{
              fontSize: '13px',
            }}
          >
            Files*
          </InputLabel>
          <Box mt={0.8} ml={1}>
            <Button variant="outlined" component="label">
              Select Folder
              <input
                onChange={getSelectedFiles}
                ref={fileUploader}
                type="file"
                id="file"
                multiple
                hidden
                {...otherAtt}
              />
            </Button>
            {files.length > 0 && <span> {files.length} files selected</span>}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectAdd;
