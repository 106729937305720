import { Watch } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { formatTimeHrMin } from '../../utils/datetime';

const TimeCard = ({ time }: any) => {
  const theme = useTheme();

  return (
    <>
      <Card sx={{ display: 'flex', bgcolor: theme.palette.secondary.light }}>
        <Box sx={{ marginX: 'auto', textAlign: 'center', marginTop: 1, color: 'black' }}>
          <CardContent>
            <Avatar
              sx={{
                bgcolor: 'rgb(255, 255, 255, 0.4)',
                marginX: 'auto',
                marginBottom: 2,
                width: 55,
                height: 55,
              }}
            >
              <Watch />
            </Avatar>
            <Box>
              <Typography variant="h4">{formatTimeHrMin(time)}</Typography>
              <Typography variant="body2">Total time spent</Typography>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default TimeCard;
