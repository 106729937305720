import { Box, Button, Divider, Typography, useTheme, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import AlertDialog from '../Common/AlertDialog';
import SequenceInfoBox from './SequenceInfoBox';
const QuestionnaireTree = ({
  currentAction,
  level,
  updateAnnotation,
  setCurrentAction,
  currentAnnotation,
  annotationExists,
  questionnaireType,
  getDependantChildren,
  getAssociatedPolygonsWithLevel,
  questionType,
}: any) => {
  const theme = useTheme();
  //const { setCurrentAction, currentAnnotation, annotationExists } = useContext(AnnotationContext);
  const { isAnnotator } = useContext(AuthContext);
  const { label, options, selected, color } = level;
  const [tempSelectedOption, setTempSelectedOption] = useState<any>(null);
  const [openLockAlertDialog, setOpenLockAlertDialog] = useState(false);
  const [alertDialogText, setAlertDialogText] = useState<string>('');
  const [_option, set_option] = useState<any>(null);
  //const [option, setOption] = useState<any>(null);
  const [lockUpdateAnnotation, setLockUpdateAnnotation] = useState<boolean>(false);

  useEffect(() => {
    if (!currentAction) return;
    if (currentAction.id !== level.id) {
      setTempSelectedOption(null);
    }
    //setCurrentAction(null);
    ////handleSelectOption(tempOption);
    //setTempSelectedOption(tempOption);
  }, [currentAction]);

  useEffect(() => {
    if (!tempSelectedOption) return;
    const tempOption = { ...tempSelectedOption };
    delete tempOption.action;
    //tempOption.coordinates = currentAnnotation.coordinates;
    if (questionnaireType === 'user_annotation') {
      tempOption.coordinates = currentAnnotation.coordinates;
      tempOption.annotoriousFormat = currentAnnotation.annotoriousFormat;
      setTempSelectedOption(null);
      setCurrentAction(null);
      handleSelectOption(tempOption);
    } else {
      setCurrentAction(null);
      setTempSelectedOption(null);
    }

    //setCurrentAction(null);
    ////handleSelectOption(tempOption);
    //setTempSelectedOption(tempOption);
  }, [currentAnnotation]);

  useEffect(() => {
    if (!_option) return;
    if (!tempSelectedOption) return;
    if (openLockAlertDialog) return;
    if (lockUpdateAnnotation) return;

    //setTempSelectedOption(null);
    //setCurrentAction(null);
    const newLevel = { ...level };
    newLevel.selected = _option.id;
    if (questionnaireType === 'user_annotation') {
      newLevel.coordinates = _option.coordinates;
      newLevel.annotoriousFormat = _option.annotoriousFormat;
    } else {
      if (tempSelectedOption.id !== 1) {
        newLevel.coordinates = tempSelectedOption.coordinates;
        newLevel.annotoriousFormats = tempSelectedOption.annotoriousFormats;
      } else {
        if (tempSelectedOption.coordinates !== undefined) {
          newLevel.coordinates = tempSelectedOption.coordinates;
          newLevel.annotoriousFormats = tempSelectedOption.annotoriousFormats;
        }
      }
    }
    if (_option.action) {
      //setTempSelectedOption(option);
      //setCurrentAction({ ...option.action, color: level.color, id: level.id, label: level.label });
      //newLevel.selected = null;
      //newLevel.completed = false;

      if (questionnaireType === 'user_annotation') {
        newLevel.selected = null;
        newLevel.completed = false;
      }
      newLevel.completed = false;
      setTempSelectedOption(_option);
      setCurrentAction({ ..._option.action, color: level.color, id: level.id, label: level.label });
    } else {
      newLevel.completed = true;
    }
    set_option(null);
    updateAnnotation(newLevel, _option.terminal);
  }, [tempSelectedOption, lockUpdateAnnotation]);

  const handleCloseLockAlertDialog = () => {
    setOpenLockAlertDialog(false);
    setLockUpdateAnnotation(false);
    setTempSelectedOption(null);
  };

  const handleSelectOption = (selectedOption: any) => {
    setTempSelectedOption(null);
    setCurrentAction(null);
    set_option(selectedOption);
    // are we dealing with grund truth questionnaire
    if (questionnaireType === 'ground_truth_annotation') {
      if (selectedOption.value === 0) {
        // deleting polygon
        const associatedPolygons = getAssociatedPolygonsWithLevel(level);
        setTempSelectedOption(selectedOption);
        if (associatedPolygons.annotoriousFormats) {
          setAlertDialogText(
            'Selecting this option will permanentely delete the associated polygon. Please click Confirm to proceed.'
          );
          setOpenLockAlertDialog(true);
          setLockUpdateAnnotation(true);
        } else {
          setOpenLockAlertDialog(false);
          setLockUpdateAnnotation(false);
        }
      } else {
        // adding polygon
        const dependantChildren = getDependantChildren(level);
        if (dependantChildren.length == 0) {
          if (tempSelectedOption && tempSelectedOption.id === selectedOption.id) {
            // the answer did not change
            // if clicked on "Visible" -> add new polygon
            // clicked on visible 2 times
            if (currentAction.id === level.id) {
              setTempSelectedOption(null);
              setCurrentAction(null);
              //setTempSelectedOption({ ...tempSelectedOption, selected: null });
              //set_option({ ..._option, id: null, action: null });
            } else {
              const newLevel = { ...level };
              //newLevel.completed = true;
              setTempSelectedOption(selectedOption);
              setCurrentAction({ ...selectedOption.action, color: level.color, id: level.id, label: level.label });
              updateAnnotation(newLevel, selectedOption.terminal);
            }
          } else {
            setTempSelectedOption(selectedOption);
          }
        } else {
          let triggerAlert = false;
          for (const level of dependantChildren) {
            if (level.selected === null) continue;
            if (!level.condition.selected) continue;
            if (level.condition.selected !== selectedOption.id) {
              triggerAlert = true;
            }
          }

          setTempSelectedOption(selectedOption);

          if (triggerAlert) {
            setAlertDialogText(
              'Selecting this option will permanentely delete answers to dependant questions. Please click Confirm to proceed.'
            );
            setOpenLockAlertDialog(true);
            setLockUpdateAnnotation(true);
          } else {
            setOpenLockAlertDialog(false);
            setLockUpdateAnnotation(false);
          }
        }
      }
    } else {
      const newLevel = { ...level };
      newLevel.selected = selectedOption.id;
      newLevel.coordinates = selectedOption.coordinates;
      newLevel.annotoriousFormat = selectedOption.annotoriousFormat;
      if (selectedOption.action) {
        setTempSelectedOption(selectedOption);
        setCurrentAction({ ...selectedOption.action, color: level.color, id: level.id, label: level.label });
        newLevel.selected = null;
        newLevel.completed = false;
      } else {
        newLevel.completed = true;
      }
      updateAnnotation(newLevel, selectedOption.terminal);
    }
  };

  const getButtonVariant = (option: any) => {
    if (selected === option.id) {
      const associatedPolygons = getAssociatedPolygonsWithLevel(level);
      if (associatedPolygons && tempSelectedOption?.id == option.id && option.id == 1) return 'outlined';
      return 'contained';
    }
    return 'outlined';
  };

  const getButtonColor = (option: any) => {
    if (!color) return null;
    // if nothing is selected return primary
    if (selected === null && !tempSelectedOption) return theme.palette.primary.main;
    // if not visible is selected return 'white;
    if (selected === option.id && option.id === 0) return 'white';
    // if visible is selected but no annotation yet
    if (tempSelectedOption?.id === option.id) return theme.palette.warning.main;
    // if visible is selected and there is annotation
    if (selected === option.id && option.id === 1) return 'white';

    return theme.palette.primary.main;
  };

  const getButtonBackgroundColor = (option: any) => {
    if (!color) {
      if (questionnaireType === 'ground_truth_annotation' && level.completed && selected === option.id)
        return theme.palette.success.main;
      return null;
    }
    // if nothing is selected return primary
    if (selected === null && !tempSelectedOption) return null;
    // if not visible is selected return 'white;
    if (selected === option.id && option.id === 0) return theme.palette.primary.main;
    // if visible is selected but no annotation yet
    if (tempSelectedOption?.id === option.id) return null;
    // if visible is selected and there is annotation
    if (selected === option.id && option.id === 1) return color;
    return null;
  };

  return (
    <>
      <>
        {level.display_info ? <SequenceInfoBox /> : null}
        <Box m={1}>
          <Typography variant="caption">{label}</Typography>
        </Box>
        <Box
          m={1}
          sx={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          {options.map((option: any) => (
            <Fragment key={option.id}>
              <Box mx={1}>
                <Button
                  variant={getButtonVariant(option)}
                  //variant={'contained'}
                  // color={getButtonColor(option)}
                  onClick={() => handleSelectOption(option)}
                  sx={{
                    minHeight: 40,
                    color: getButtonColor(option),
                    borderColor: getButtonColor(option),
                    backgroundColor: getButtonBackgroundColor(option),
                  }}
                  size="small"
                  disabled={annotationExists}
                >
                  {option.label}
                </Button>
              </Box>
            </Fragment>
          ))}
          {questionType === 'annotation' ? (
            <Tooltip
              title={
                isAnnotator()
                  ? 'To add a new annotation please click ' +
                    '"visible"' +
                    '. To delete whole annotation category, click ' +
                    '"not visible"' +
                    '. To adjust an existing annotation please click on the annotation and adjust by drag and drop the orange colored points.'
                  : 'Please point out where in the image you see the respective feature. To do so please click ' +
                    '"visible"' +
                    '. If feature is not visible in this image click ' +
                    '"not visible"' +
                    '. Only one dot per feature can be annotated. Clicking on an existing annotation until it turns to orange color allows you to update its position by dragging and dropping'
              }
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
        {tempSelectedOption?.action && (
          <Typography variant="caption">{tempSelectedOption.action.description}</Typography>
        )}
        <Divider />
      </>
      <AlertDialog
        open={openLockAlertDialog}
        handleClose={handleCloseLockAlertDialog}
        action={() => {
          setOpenLockAlertDialog(false);
          setLockUpdateAnnotation(false);
        }}
        title="Warning"
        text={alertDialogText}
      />
    </>
  );
};

export default QuestionnaireTree;
