import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';

const AccordionWrapper = ({ title, isExpanded, handleChange, children }: any) => {
  return (
    <>
      <Box my={1}>
        <Accordion expanded={isExpanded} onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="caption">{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </Box>
      <Divider />
    </>
  );
};

export default AccordionWrapper;
