import { Button, Dialog, DialogActions, DialogContent, Box, Typography, Divider, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { CommonContext } from '../context/CommonContext';
const ProfileDialog = ({ open, handleClose }: any) => {
  const { user, updatePassword } = useContext(AuthContext);
  const { setAlertMessage, setInfoMessage } = useContext(CommonContext);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypeNewPassword, setReTypeNewPassword] = useState('');

  const handleupdatePassword = async () => {
    if (oldPassword === '') {
      setAlertMessage('Please input the old password');
      return;
    }
    if (newPassword === '') {
      setAlertMessage('Please enter the new password');
      return;
    }
    if (reTypeNewPassword === '') {
      setAlertMessage('Please retype the new password');
      return;
    }
    if (!(newPassword === reTypeNewPassword)) {
      setAlertMessage('Password mismatch');
      return;
    }

    try {
      if (!(await updatePassword(oldPassword, newPassword))) {
        setAlertMessage('Error updating password');
        return;
      } else {
        setInfoMessage('Password successfully updated');
        handleClose();
      }
    } catch (err) {
      setAlertMessage('Error updating password');
      return;
    }
  };

  const updateOldPassword = (event: any) => {
    setOldPassword(event.target.value);
  };

  const updateNewPassword = (event: any) => {
    setNewPassword(event.target.value);
  };

  const updateReTypeNewPassword = (event: any) => {
    setReTypeNewPassword(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
      <DialogContent>
        <Box m={2}>
          <Typography variant="h3" gutterBottom>
            Profile information
          </Typography>
        </Box>
        <Box m={2}>
          <Box m={1}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Username</span>: {user.username}
            </Typography>
          </Box>
          <Box m={1}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Role</span>: {user.role}
            </Typography>
          </Box>
          <Box m={1}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Created</span>: {user.created}
            </Typography>
          </Box>
          <Box m={1}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Last updated</span>: {user.last_updated}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box m={2}>
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Change password</span>
          </Typography>
          <Box m={1}>
            <TextField
              onChange={updateOldPassword}
              id="old-password"
              label="Old password"
              variant="standard"
              type="password"
            />
          </Box>
          <Box m={1}>
            <TextField
              onChange={updateNewPassword}
              id="new-password"
              label="New password"
              variant="standard"
              type="password"
            />
          </Box>
          <Box m={1}>
            <TextField
              onChange={updateReTypeNewPassword}
              id="retype-new-password"
              label="Retype new password"
              variant="standard"
              type="password"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleupdatePassword} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileDialog;
