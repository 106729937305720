import { ContactlessOutlined } from '@mui/icons-material';
import axios from 'axios';
import { template } from 'lodash';
import { createContext, Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import COLORS from '../constants/colors.json';
import { annotationMetaData } from '../utils/annotationImport';

interface GroundTruthAnnotationContextInterface {
  groundTruthAnnotation: any;
  preAnnotation: any;
  currentGroundTruthAnnotationStep: any;
  currentGroundTruthAnnotationStepIndex: number;
  getGroundTruth: (stageId: number, sequenceId: number, alertCallback: (message: string) => void) => void;
  setCurrentGroundTruthAction: Dispatch<SetStateAction<any>>;
  currentGroundTruthAnnotation: any;
  groundTruthAnnotationExists: boolean;
  updateGroundTruthCoordinates: (coordinateId: string, coordinates: any, annotoriousFormat: any) => void;
  deleteGroundTruthCoordinates: (coordinateId: string) => void;
  currentGroundTruthAction: any;
  tempUpdateGroundTruthAnnotation: (updatedGroundTruthAnnotationStep: any) => void;
  saveCoordinatesGroundTruth: (coordinates: any, annotoriousFormat: any) => void;
  getPreviousGroundTruthAnnotationStep: () => void;
  getNextGroundTruthAnnotationStep: () => void;
  getAssociatedPolygonsWithLevel: (level: string) => void;
  groundTruthAnnotationLength: number;
  updateGroundTruthAnnotation: (alertCallback: (message: string) => void) => void;
  handleUndoEventGroundTruthAnnotation: () => void;
  groundTruthAnnotationHistory: any;
  undoAvailable: boolean;
}

export const GroundTruthAnnotationContext = createContext<GroundTruthAnnotationContextInterface>({
  groundTruthAnnotation: null,
  preAnnotation: null,
  undoAvailable: false,
  currentGroundTruthAnnotationStep: null,
  groundTruthAnnotationHistory: [],
  currentGroundTruthAnnotationStepIndex: -1,
  getGroundTruth: () => undefined,
  setCurrentGroundTruthAction: () => undefined,
  currentGroundTruthAnnotation: null,
  groundTruthAnnotationExists: false,
  updateGroundTruthCoordinates: () => undefined,
  deleteGroundTruthCoordinates: () => undefined,
  currentGroundTruthAction: null,
  tempUpdateGroundTruthAnnotation: () => undefined,
  saveCoordinatesGroundTruth: () => undefined,
  getPreviousGroundTruthAnnotationStep: () => undefined,
  getNextGroundTruthAnnotationStep: () => undefined,
  groundTruthAnnotationLength: 0,
  updateGroundTruthAnnotation: () => undefined,
  getAssociatedPolygonsWithLevel: () => undefined,
  handleUndoEventGroundTruthAnnotation: () => undefined,
});

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};

export const GroundTruthAnnotationProvider = ({ children }: any) => {
  const [undoAvailable, setUndoAvailable] = useState<boolean>(false);
  const [groundTruthAnnotation, setGroundTruthAnnotation] = useState<any>();
  const [preAnnotation, setPreAnnotation] = useState<any>();
  const prevGroundTruthAnnotation: any = usePrevious(groundTruthAnnotation);
  const [groundTruthAnnotationType, setGroundTruthAnnotationType] = useState<any>();
  const [currentGroundTruthAnnotationStep, setCurrentGroundTruthAnnotationStep] = useState<any>();
  const prevGroundTruthAnnotationStep: any = usePrevious(currentGroundTruthAnnotationStep);
  const [currentGroundTruthAnnotationStepIndex, setCurrentGroundTruthAnnotationIndex] = useState(-1);
  const [currentGroundTruthAction, setCurrentGroundTruthAction] = useState(null);
  const [currentGroundTruthAnnotation, setCurrentGroundTruthAnnotation] = useState<any>(null);
  const [groundTruthAnnotationExists, setGroundTruthAnnotationExists] = useState(false);
  const [groundTruthAnnotationLength, setGroundTruthAnnotationLength] = useState(0);
  const [groundTruthAnnotationHistory, setGroundTruthAnnotationHistory] = useState<any>([]);
  const [groundTruthAnnotationStepHistory, setGroundTruthAnnotationStepHistory] = useState<any>([]);

  useEffect(() => {
    if (!groundTruthAnnotation) return;
    const newGroundTruthAnnotationStep = {
      ...groundTruthAnnotation.annotation_tree.steps[currentGroundTruthAnnotationStepIndex],
    };
    if (newGroundTruthAnnotationStep.template.length === 0) {
      newGroundTruthAnnotationStep.completed = true;
    }
    setCurrentGroundTruthAnnotationStep(newGroundTruthAnnotationStep);
    setGroundTruthAnnotationLength(groundTruthAnnotation.annotation_tree.steps.length);
  }, [groundTruthAnnotation]);

  useEffect(() => {
    // do double checking and corrections
    if (!currentGroundTruthAnnotationStep) return;
    if (currentGroundTruthAnnotationStep.type === 'annotation') {
      //let changed = false;
      const changedCurrentGroundTruthAnnotationStep = { ...currentGroundTruthAnnotationStep };
      //for (let t = 0; t < changedCurrentGroundTruthAnnotationStep.template.length; t++) {
      //  const template = changedCurrentGroundTruthAnnotationStep.template[t];
      //  if (template.selected === 1) {
      //    if (!template.annotoriousFormats) {
      //      if (template.completed) {
      //        changed = true;
      //        template.completed = false;
      //        changedCurrentGroundTruthAnnotationStep.template[t] = template;
      //      }
      //    }
      //  }
      //}

      const allTemplatesCompleted = changedCurrentGroundTruthAnnotationStep.template.every((template: any) => {
        //if (template.completed !== undefined) {
        //  return template.completed;
        //} else {
        if (template.selected === 1 && template.annotoriousFormats) {
          return true;
        } else {
          return template.selected === 0;
        }
      });
      if (allTemplatesCompleted !== changedCurrentGroundTruthAnnotationStep.completed) {
        changedCurrentGroundTruthAnnotationStep.completed = allTemplatesCompleted;
        setCurrentGroundTruthAnnotationStep(changedCurrentGroundTruthAnnotationStep);
      }
    }
  }, [currentGroundTruthAnnotationStep]);

  useEffect(() => {
    if (!groundTruthAnnotation) return;
    const newGroundTruthAnnotationStep = {
      ...groundTruthAnnotation.annotation_tree.steps[currentGroundTruthAnnotationStepIndex],
    };
    if (newGroundTruthAnnotationStep.template.length === 0) {
      newGroundTruthAnnotationStep.completed = true;
    }
    if (currentGroundTruthAnnotationStepIndex === 0) {
      setUndoAvailable(false);
    }

    setCurrentGroundTruthAnnotationStep(newGroundTruthAnnotationStep);
  }, [currentGroundTruthAnnotationStepIndex]);

  const handleRowChange = (tempGroundTruthAnnotation: any, templateIndex: number, row: any) => {
    const templateIndexInAnnotation = tempGroundTruthAnnotation.annotation_tree.steps[1].template.findIndex(
      (template: any) => {
        return row.id === template.id;
      }
    );
    if (row.selected === 0 || row.selected === null) {
      if (templateIndexInAnnotation === -1) return tempGroundTruthAnnotation;
      tempGroundTruthAnnotation.annotation_tree.steps[1].template.splice(templateIndexInAnnotation, 1);
    } else {
      if (templateIndexInAnnotation === -1) {
        let template = {
          id: row.id,
          type: annotationMetaData[row.id].type,
          label: annotationMetaData[row.id].label,
          color: annotationMetaData[row.id].color,
          options: annotationMetaData[row.id].options,
          selected: null,
          coordinates: null,
          annotoriousFormats: null,
          completed: false,
          terminal: false,
        };
        if (preAnnotation) {
          const templateIndexInPreAnnotation = preAnnotation.annotation_tree.steps[1].template.findIndex(
            (template: any) => {
              return row.id === template.id;
            }
          );
          if (templateIndexInPreAnnotation !== -1) {
            template = preAnnotation.annotation_tree.steps[1].template[templateIndexInPreAnnotation];
          }
        }
        tempGroundTruthAnnotation.annotation_tree.steps[1].template.push(template);
      }
    }
    return tempGroundTruthAnnotation;
  };
  const checkIfTerminal = (tempGroundTruthAnnotation: any) => {
    for (const template of tempGroundTruthAnnotation.annotation_tree.steps[0].template) {
      if (template.selected !== null) {
        if (!template.options) continue;
        const option = template.options.find((_option: any) => {
          return _option.id === template.selected;
        });
        if (template.completed && option.terminal) {
          tempGroundTruthAnnotation.annotation_tree.steps[0].completed = true;
          return tempGroundTruthAnnotation;
        }
      }
    }
    return tempGroundTruthAnnotation;
  };

  const tempUpdateGroundTruthAnnotation = (updatedGroundTruthAnnotationStep: any) => {
    if (updatedGroundTruthAnnotationStep.type === 'annotation') {
      setGroundTruthAnnotationStepHistory((groundTruthAnnotationStepHistory: any) => {
        setUndoAvailable(true);
        return JSON.parse(
          JSON.stringify([...groundTruthAnnotationStepHistory, { ...currentGroundTruthAnnotationStep }])
        );
      });
    }

    let tempGroundTruthAnnotation = { ...groundTruthAnnotation };
    tempGroundTruthAnnotation.annotation_tree.steps[currentGroundTruthAnnotationStepIndex] =
      updatedGroundTruthAnnotationStep;
    if (updatedGroundTruthAnnotationStep.type === 'questionnaire') {
      //const template = tempGroundTruthAnnotation.annotation_tree.steps[0].template[1];
      const radioGridTemplates = tempGroundTruthAnnotation.annotation_tree.steps[0].template.filter((template: any) => {
        return template.type === 'radio-grid';
      });
      for (const template of radioGridTemplates) {
        const templateIndex = tempGroundTruthAnnotation.annotation_tree.steps[0].template.findIndex(
          (_template: any) => {
            return _template.id === template.id;
          }
        );
        for (const row of template.rows) {
          tempGroundTruthAnnotation = handleRowChange(tempGroundTruthAnnotation, templateIndex, row);
        }
      }
      tempGroundTruthAnnotation = checkIfTerminal(tempGroundTruthAnnotation);
    }

    setGroundTruthAnnotation(tempGroundTruthAnnotation);
  };

  const getNextGroundTruthAnnotationStep = () => {
    setCurrentGroundTruthAnnotationIndex(currentGroundTruthAnnotationStepIndex + 1);
  };

  const getPreviousGroundTruthAnnotationStep = () => {
    // reset current annotation step
    const newGroundTruthAnnotationStep = { ...currentGroundTruthAnnotationStep };
    newGroundTruthAnnotationStep.completed = false;
    //for (const category of newGroundTruthAnnotationStep.template) {
    //  category.coordinates = null;
    //  category.annotoriousFormats = null;
    //  category.selected = null;
    //  category.completed = false;
    //}
    tempUpdateGroundTruthAnnotation(newGroundTruthAnnotationStep);
    setCurrentGroundTruthAnnotationIndex(currentGroundTruthAnnotationStepIndex - 1);
    setUndoAvailable(false);
    setGroundTruthAnnotationStepHistory([]);
  };

  const getGroundTruth = async (stageId: number, sequenceId: number, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.get(`/stage/${stageId}/sequence/${sequenceId}/ground_truth`);
      setGroundTruthAnnotation(res.data);
      setGroundTruthAnnotationType('expert_annotation');
      setCurrentGroundTruthAnnotationIndex(0);
      setGroundTruthAnnotationExists(false);
      setGroundTruthAnnotationLength(res.data.annotation_tree.steps.length);
      setGroundTruthAnnotationStepHistory([]);
      const preanno_res = await axios.get(
        `/stage/${stageId}/sequence/${sequenceId}/ground_truth/ground_truth_annotation`
      );
      setPreAnnotation(preanno_res.data);
    } catch (err) {
      try {
        const res = await axios.get(`/stage/${stageId}/sequence/${sequenceId}/ground_truth/ground_truth_annotation`);
        setGroundTruthAnnotation(res.data);
        setGroundTruthAnnotationType('ground_truth_annotation');
        setCurrentGroundTruthAnnotationIndex(0);
        setGroundTruthAnnotationExists(false);
        setGroundTruthAnnotationStepHistory([]);
      } catch (err) {
        setGroundTruthAnnotation(null);
        setGroundTruthAnnotationType('');
        setGroundTruthAnnotationExists(false);
        setGroundTruthAnnotationLength(0);
        setCurrentGroundTruthAnnotationIndex(-1);
        setGroundTruthAnnotationStepHistory([]);
      }
    }
  };

  const postExpertAnnotation = async (groundTruthAnnotation: any, alertCallback: (message: string) => void) => {
    await axios.post('/ground_truth', JSON.stringify(groundTruthAnnotation));
  };

  const updateExpertAnnotation = async (groundTruthAnnotation: any, alertCallback: (message: string) => void) => {
    await axios.put(
      `/ground_truth/${groundTruthAnnotation.id}`,
      JSON.stringify({
        stage_id: groundTruthAnnotation.stage_id,
        sequence_id: groundTruthAnnotation.sequence_id,
        annotation_tree: groundTruthAnnotation.annotation_tree,
        annotation_type: groundTruthAnnotation.annotation_type,
      })
    );
  };

  const updateGroundTruthAnnotation = async (alertCallback: (message: string) => void) => {
    if (groundTruthAnnotation.annotation_type === 'ground_truth_annotation') {
      groundTruthAnnotation.annotation_type = 'expert_annotation';
      delete groundTruthAnnotation.id;
      delete groundTruthAnnotation.user_id;
      delete groundTruthAnnotation.created;
      delete groundTruthAnnotation.last_updated;
      await postExpertAnnotation(groundTruthAnnotation, alertCallback);
    } else {
      await updateExpertAnnotation(groundTruthAnnotation, alertCallback);
    }
    setGroundTruthAnnotation(groundTruthAnnotation);
  };

  const getAssociatedPolygonsWithLevel = (level: any) => {
    const tempGroundTruthStep = groundTruthAnnotation.annotation_tree.steps[1];
    return tempGroundTruthStep.template.find((template: any) => {
      return template.id === level.id;
    });
  };

  const saveCoordinatesGroundTruth = (coordinates: any, annotoriousFormat: any) => {
    const coordinatesId = Math.random().toString(36).substring(2, 11);
    setCurrentGroundTruthAnnotationStep((currentGroundTruthAnnotationStep: any) => {
      setGroundTruthAnnotationStepHistory((groundTruthAnnotationStepHistory: any) => {
        setUndoAvailable(true);
        return JSON.parse(
          JSON.stringify([...groundTruthAnnotationStepHistory, { ...currentGroundTruthAnnotationStep }])
        );
      });
      if (currentGroundTruthAnnotationStep.type !== 'annotation') return currentGroundTruthAnnotationStep;
      const templateIndex = currentGroundTruthAnnotationStep.template.findIndex((temp: any) => {
        return temp.id === annotoriousFormat.body[0].value;
      });
      if (templateIndex === -1) return currentGroundTruthAnnotationStep;
      const updatedAnnotationStep = { ...currentGroundTruthAnnotationStep };

      if (!updatedAnnotationStep.template[templateIndex].coordinates) {
        updatedAnnotationStep.template[templateIndex].coordinates = {};
      }
      if (!updatedAnnotationStep.template[templateIndex].annotoriousFormats) {
        updatedAnnotationStep.template[templateIndex].annotoriousFormats = {};
      }
      annotoriousFormat.id = coordinatesId;
      updatedAnnotationStep.template[templateIndex].coordinates[coordinatesId] = coordinates;
      updatedAnnotationStep.template[templateIndex].annotoriousFormats[coordinatesId] = annotoriousFormat;
      updatedAnnotationStep.template[templateIndex].completed = true;
      updatedAnnotationStep.template[templateIndex].selected = 1;
      let completed = true;
      for (const template of updatedAnnotationStep.template) {
        if (template.selected === null) completed = false;
      }
      updatedAnnotationStep.completed = completed;

      const newCurrentGroundTruthAnnotation: any = {
        coordinates: updatedAnnotationStep.template[templateIndex].coordinates,
        annotoriousFormats: updatedAnnotationStep.template[templateIndex].annotoriousFormats,
      };
      setCurrentGroundTruthAnnotation(newCurrentGroundTruthAnnotation);
      setCurrentGroundTruthAction(null);
      return updatedAnnotationStep;
    });
  };

  const updateGroundTruthCoordinates = (coordinatesId: string, coordinates: any, annotoriousFormat: any) => {
    setCurrentGroundTruthAnnotationStep((currentGroundTruthAnnotationStep: any) => {
      if (currentGroundTruthAnnotationStep.type !== 'annotation') return currentGroundTruthAnnotationStep;
      setGroundTruthAnnotationStepHistory((groundTruthAnnotationStepHistory: any) => {
        setUndoAvailable(true);
        return JSON.parse(
          JSON.stringify([...groundTruthAnnotationStepHistory, { ...currentGroundTruthAnnotationStep }])
        );
      });
      const templateIndex = currentGroundTruthAnnotationStep.template.findIndex(
        //(temp: any) => temp.annotoriousFormat.id === annotoriousFormat.id
        (temp: any) => {
          if (temp.coordinates) {
            return annotoriousFormat.id in temp.coordinates;
          } else {
            return false;
          }
        }
      );
      if (templateIndex === -1) return currentGroundTruthAnnotationStep;
      const updatedAnnotationStep = { ...currentGroundTruthAnnotationStep };
      updatedAnnotationStep.template[templateIndex].coordinates[coordinatesId] = coordinates;
      updatedAnnotationStep.template[templateIndex].annotoriousFormats[coordinatesId] = annotoriousFormat;
      const newCurrentGroundTruthAnnotation: any = {
        coordinates: updatedAnnotationStep.template[templateIndex].coordinates,
        annotoriousFormats: updatedAnnotationStep.template[templateIndex].annotoriousFormats,
      };
      setCurrentGroundTruthAnnotation(newCurrentGroundTruthAnnotation);
      return updatedAnnotationStep;
    });
  };

  const deleteGroundTruthCoordinates = (coordinatesId: string) => {
    setCurrentGroundTruthAnnotationStep((currentGroundTruthAnnotationStep: any) => {
      if (currentGroundTruthAnnotationStep.type !== 'annotation') return currentGroundTruthAnnotationStep;
      setGroundTruthAnnotationStepHistory((groundTruthAnnotationStepHistory: any) => {
        setUndoAvailable(true);
        return JSON.parse(
          JSON.stringify([...groundTruthAnnotationStepHistory, { ...currentGroundTruthAnnotationStep }])
        );
      });
      const templateIndex = currentGroundTruthAnnotationStep.template.findIndex(
        //(temp: any) => temp.annotoriousFormat.id === annotoriousFormat.id
        (temp: any) => {
          if (temp.coordinates) {
            return coordinatesId in temp.coordinates;
          } else {
            return false;
          }
        }
      );
      if (templateIndex === -1) return currentGroundTruthAnnotationStep;
      const updatedAnnotationStep = { ...currentGroundTruthAnnotationStep };
      delete updatedAnnotationStep.template[templateIndex].coordinates[coordinatesId];
      delete updatedAnnotationStep.template[templateIndex].annotoriousFormats[coordinatesId];
      if (Object.keys(updatedAnnotationStep.template[templateIndex].annotoriousFormats).length === 0) {
        updatedAnnotationStep.template[templateIndex].annotoriousFormats = null;
        updatedAnnotationStep.template[templateIndex].coordinates = null;
        updatedAnnotationStep.template[templateIndex].selected = null;
        updatedAnnotationStep.template[templateIndex].completed = false;
        updatedAnnotationStep.completed = false;
      }
      const newCurrentGroundTruthAnnotation: any = {
        coordinates: updatedAnnotationStep.template[templateIndex].coordinates,
        annotoriousFormats: updatedAnnotationStep.template[templateIndex].annotoriousFormats,
      };
      setCurrentGroundTruthAnnotation(newCurrentGroundTruthAnnotation);

      return updatedAnnotationStep;
    });
  };

  const handleUndoEventGroundTruthAnnotation = () => {
    const newHistoryStep = [...groundTruthAnnotationStepHistory];
    if (currentGroundTruthAnnotationStep.type !== 'annotation') return currentGroundTruthAnnotationStep;
    const prevAnnotationStep = newHistoryStep.pop();
    if (prevAnnotationStep) {
      setCurrentGroundTruthAnnotationStep(prevAnnotationStep);
    }
    if (newHistoryStep.length === 0) {
      setUndoAvailable(false);
    }
    setGroundTruthAnnotationStepHistory(newHistoryStep);
  };

  const value = {
    groundTruthAnnotation,
    preAnnotation,
    getGroundTruth,
    currentGroundTruthAnnotationStep,
    currentGroundTruthAnnotationStepIndex,
    currentGroundTruthAction,
    setCurrentGroundTruthAction,
    currentGroundTruthAnnotation,
    groundTruthAnnotationExists,
    updateGroundTruthCoordinates,
    tempUpdateGroundTruthAnnotation,
    saveCoordinatesGroundTruth,
    getPreviousGroundTruthAnnotationStep,
    getNextGroundTruthAnnotationStep,
    groundTruthAnnotationLength,
    updateGroundTruthAnnotation,
    getAssociatedPolygonsWithLevel,
    deleteGroundTruthCoordinates,
    handleUndoEventGroundTruthAnnotation,
    groundTruthAnnotationHistory,
    undoAvailable,
  };

  return <GroundTruthAnnotationContext.Provider value={value}>{children}</GroundTruthAnnotationContext.Provider>;
};
