import { createContext, useState } from 'react';
import axios, { setAuthToken } from '../utils/axiosUtils';

interface AuthContextInterface {
  isAuthenticated: boolean;
  user: any;
  login: (username: string, password: string) => void;
  updatePassword: (oldPassword: string, newPassword: string) => any;
  logout: () => void;
  loadUser: () => void;
  loading: boolean;
  isAdmin: () => boolean;
  isAnalyst: () => boolean;
  isAnnotator: () => boolean;
}

export const AuthContext = createContext<AuthContextInterface>({
  isAuthenticated: false,
  user: null,
  login: () => undefined,
  logout: () => undefined,
  loadUser: () => undefined,
  loading: true,
  isAdmin: () => false,
  isAnalyst: () => false,
  isAnnotator: () => false,
  updatePassword: () => false,
});

export const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);

  const isAdmin = () => {
    if (!user) return false;
    if (user.role === 'admin') return true;
    return false;
  };

  const isAnnotator = () => {
    if (!user) return false;
    if (user.role === 'annotator') return true;
    return false;
  };

  const isAnalyst = () => {
    if (!user) return false;
    if (user.role === 'admin') return true;
    if (user.role === 'analyst') return true;
    return false;
  };

  const login = async (username: string, password: string) => {
    setLoading(true);
    const body = JSON.stringify({ username, password });
    try {
      const res = await axios.post('/login', body);
      localStorage.setItem('token', res.data.access_token);
      setAuthToken(res.data.access_token);
      setUser(res.data.user);
      setIsAuthenticated(true);
      setLoading(false);
    } catch (err) {
      setAuthToken(null);
      setIsAuthenticated(false);
      setUser({});
      setLoading(false);
      throw Error('Wrong Credentials');
    }
  };

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const body = JSON.stringify({ oldPassword, newPassword });
      const res = await axios.post('/update_password', body);
      return true;
    } catch (err) {
      console.error(err);
      return false;
      //throw Error('Error when updating password');
    }
  };

  const logout = async () => {
    try {
      await axios.post('/logout');
      setAuthToken(null);
      setUser({});
      setIsAuthenticated(false);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const loadUser = async () => {
    setAuthToken(localStorage.getItem('token'));
    try {
      const res = await axios.get('/user/me');
      setUser(res.data);
      setIsAuthenticated(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const value = {
    isAuthenticated,
    user,
    login,
    logout,
    loadUser,
    loading,
    isAdmin,
    isAnalyst,
    isAnnotator,
    updatePassword,
  };

  return <AuthContext.Provider value={value as AuthContextInterface}>{children}</AuthContext.Provider>;
};
