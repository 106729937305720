import { Lock } from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/datetime';

const thumbnails = [
  'https://mil-s3-test.s3.eu-central-1.amazonaws.com/20200601_09543653_ISconf.jpg',
  'https://mil-s3-test.s3.eu-central-1.amazonaws.com/20200601_09543682_ISconf.jpg',
];

const ProjectCard = ({ project }: any) => {
  return (
    <Link to={`/projects/${project.id}`} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          maxWidth: 600,
          border: '1px solid black',
          '&:hover': {
            border: '1px solid',
            borderColor: 'primary.main',
            transform: 'scale(1.03)',
          },
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            alt={project.name}
            height="150"
            image={thumbnails[project.id % thumbnails.length]}
            title={project.name}
          />
          <CardContent
            sx={{
              height: 145,
            }}
          >
            <Typography variant="h5" component="h2">
              {project.name}
              {project.locked && (
                <Tooltip title="Project is currently locked">
                  <Lock color="secondary" />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2" component="p" color="primary">
              Last modified: {formatDate(project.last_updated)}
            </Typography>
            <Box mt={1}>
              <Typography variant="body2" component="p">
                {project.info}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default ProjectCard;
