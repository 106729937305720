import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';

const InfoSnackbar = () => {
  const { infoMessage, setInfoMessage } = useContext(CommonContext);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setInfoMessage('');
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (infoMessage === '') return;
    handleOpenSnackbar();
  }, [infoMessage]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={openSnackbar}
      onClose={handleCloseSnackbar}
      autoHideDuration={4000}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert onClose={handleCloseSnackbar} severity="info">
        {infoMessage}
      </Alert>
    </Snackbar>
  );
};

export default InfoSnackbar;
