import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';

interface CommonContextInterface {
  alertMessage: string;
  setAlertMessage: Dispatch<SetStateAction<string>>;
  infoMessage: string;
  setInfoMessage: Dispatch<SetStateAction<string>>;
  loading: boolean;
  loadingText: string;
  deleteCases: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setLoadingText: Dispatch<SetStateAction<string>>;
  setDeleteCases: Dispatch<SetStateAction<boolean>>;
}

export const CommonContext = createContext<CommonContextInterface>({
  alertMessage: '',
  setAlertMessage: () => undefined,
  infoMessage: '',
  setInfoMessage: () => undefined,
  loading: false,
  loadingText: '',
  deleteCases: false,
  setLoading: () => undefined,
  setLoadingText: () => undefined,
  setDeleteCases: () => undefined,
});

export const CommonProvider = ({ children }: any) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [deleteCases, setDeleteCases] = useState(false);

  useEffect(() => {
    if (!loading) {
      setLoadingText('');
    }
  }, [loading]);

  const value = {
    alertMessage,
    setAlertMessage,
    setInfoMessage,
    infoMessage,
    loading,
    setLoading,
    setDeleteCases,
    deleteCases,
    loadingText,
    setLoadingText,
  };

  return <CommonContext.Provider value={value as CommonContextInterface}>{children}</CommonContext.Provider>;
};
