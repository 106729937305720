import { ConstructionOutlined, QrCodeScannerOutlined } from '@mui/icons-material';
import axios from 'axios';
import Chance from 'chance';
import { imageFormatSupported } from 'openseadragon';
import { createContext, useEffect, useState, useContext } from 'react';
import Images from '../routes/Images';
import { AuthContext } from './AuthContext';
import { CommonContext } from './CommonContext';
interface SequencesContextInterface {
  sequences: any[];
  sequence: any;
  getSequence: (id: number) => void;
  getNextSequence: () => any | null;
  getNextIncompleteSequence: () => any | null;
  getPreviousSequence: () => any | null;
  deleteSequence: (sequenceId: number, alertCallback: (message: string) => void) => void;
  images: any[];
  image: any;
  imageIndex: number;
  getImage: (id: number, alertCallback: (message: string) => void) => void;
  getNextImage: () => number | null;
  goToNextImage: () => null;
  goToPrevImage: () => null;
  setMainImage: () => null;
  setSpecificImage: (image: any) => null;
  getPreviousImage: () => number | null;
  getSequences: (stageId: number, userId: number, alertCallback: (message: string) => void) => void;
  getImages: (sequenceId: number, alertCallback: (message: string) => void) => void;
  sequenceIndex: number;
}

export const SequencesContext = createContext<SequencesContextInterface>({
  sequences: [],
  sequence: null,
  getSequence: () => undefined,
  getNextSequence: () => undefined,
  getNextIncompleteSequence: () => undefined,
  getPreviousSequence: () => undefined,
  images: [],
  image: null,
  imageIndex: -1,
  getImage: () => undefined,
  getNextImage: () => null,
  goToNextImage: () => null,
  goToPrevImage: () => null,
  setMainImage: () => null,
  setSpecificImage: () => null,
  getPreviousImage: () => null,
  getSequences: () => undefined,
  deleteSequence: () => undefined,
  getImages: () => undefined,
  sequenceIndex: -1,
});

export const SequencesProvider = ({ children }: any) => {
  const [sequences, setSequences] = useState<any[]>([]);
  const [sequence, setSequence] = useState<any>(null);
  const [images, setImages] = useState<any[]>([]);
  const [image, setImage] = useState<any>(null);
  const [imageIndex, setImageIndex] = useState<number>(-1);
  const [sequenceIndex, setSequenceIndex] = useState(-1);
  const { isAnnotator, isAdmin, isAnalyst } = useContext(AuthContext);
  const { setLoading, setLoadingText } = useContext(CommonContext);
  useEffect(() => {
    if (!image || images.length === 0) return;
    const currentIndex = images.findIndex((img) => img.id === image.id);
    setImageIndex(currentIndex);
  }, [image]);

  useEffect(() => {
    if (images.length === 0) return;
    if (!sequence || sequences.length === 0) return;
    if (sequence.completed) {
      setMainImage();
    } else {
      getImage(images[0].id, (message: string) => {
        return null;
      });
    }
  }, [images]);

  useEffect(() => {
    if (!sequence || sequences.length === 0) return;
    const currentIndex = sequences.findIndex((seq) => seq.id === sequence.id);
    setSequenceIndex(currentIndex);
    getImages(sequence.id, (message: string) => {
      return null;
    });
  }, [sequence, sequences]);

  const deleteSequence = async (sequenceId: number, alertCallback: (message: string) => void) => {
    try {
      const _sequences = [...sequences];
      const sequenceIndex = _sequences.findIndex((_sequence: any) => {
        return _sequence.id == sequenceId;
      });
      if (sequenceIndex === -1) {
        alertCallback('Seqeuence not found in sequence list');
        return;
      }
      const res = await axios.delete(`sequence/${sequenceId}`);
      _sequences.splice(sequenceIndex, 1);
      setSequences(_sequences);
    } catch (err) {
      alertCallback('An error occured while deleting a sequence from a project');
      console.error(err);
    }
  };

  const fetchSequences = async (stageId: number) => {    
    const res = await axios.get(`/stage/${stageId}/sequences`);
    return res;
  };

  const setSequencesRandomly = async (stageId: number, userId: number) => {        
    const res: any = await fetchSequences(stageId);
    //console.log(res);
    const chance = new Chance(userId);
    let tempSequences = res.data.sequences;
    if(!(isAnnotator() || isAdmin() || isAnalyst())){
      tempSequences = chance.shuffle(tempSequences);
    }
    setSequences(tempSequences);    
  };

  const getSequences = async (stageId: number, userId: number, alertCallback: (message: string) => void) => {
    try {
      setLoading(true);
      setLoadingText('Loading sequences');
      await setSequencesRandomly(stageId, userId);      
      setLoading(false);
      setLoadingText('');
    } catch (err: any) {      
      if(err.response.status === 401){
        setLoading(true);
        setLoadingText('Loading sequences');
        setTimeout(async()=>{
          await setSequencesRandomly(stageId, userId);      
          setLoading(false);
          setLoadingText('');
        }, 3000);

      } else {
        alertCallback('An error occured while fetching project sequences');      
        console.error(err);
      }
    }
  };

  const getImages = async (sequenceId: number, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.get(`/sequence/${sequenceId}/images`);
      setImages(res.data.images);
      for (const _image in res.data.images) {
        const img = new Image();
        img.src = res.data.images[_image].s3_info.presigned_url;
        await img.decode();
      }

      //setImages(_images);
    } catch (err) {
      alertCallback('An error occured while fetching images metadata');
      console.error(err);
    }
  };

  const getSequence = (id: number) => {
    const newSequence = sequences.find((seq) => seq.id === id);
    if (!newSequence) return;
    setSequence(newSequence);
  };

  const getNextSequence = () => {
    if (sequenceIndex < 0) return null;
    if (sequenceIndex === sequences.length - 1) return null;
    return sequences[sequenceIndex + 1];
  };

  const getNextIncompleteSequence = () => {
    //console.log('getNextIncompleteSequence()');
    let currentSequenceIndex = 0;
    //console.log('getNextIncompleteSequence() sequence');
    //console.log(sequence);
    if(sequence){
      currentSequenceIndex = sequences.findIndex((seq) => seq.id === sequence.id);
      //console.log('getNextIncompleteSequence() currentSequenceIndex');
      //console.log(currentSequenceIndex);
      if (currentSequenceIndex === sequences.length - 1) {
        currentSequenceIndex = 0;
      }
    } 
    //console.log('getNextIncompleteSequence() currentSequenceIndex');
    //console.log(currentSequenceIndex);
    //if(!sequences[currentSequenceIndex].completed){
    //  return sequences[currentSequenceIndex];
    //}
    
    const firstIncompletedSequence = sequences.findIndex((seq, idx) => !seq.completed && idx > currentSequenceIndex);    
    if (firstIncompletedSequence === null) return null;
    return sequences[firstIncompletedSequence];

  };

  const getPreviousSequence = () => {
    if (sequenceIndex < 0) return null;
    if (sequenceIndex === 0) return sequences[sequences.length - 1];
    return sequences[sequenceIndex - 1];
  };

  const getImageInfo = async (imageId: number) => {
    try {
      return await axios.get(`/image/${imageId}`);
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const getImage = async (imageId: number, alertCallback: (message: string) => void, set = true) => {
    try {
      if (images.length == 0) return;

      const currentImage = images.filter((image: any) => {
        return image.id === imageId && image.s3_info !== undefined;
      }); //

      const imageIndex = images.findIndex((image: any) => {
        return image.id == imageId;
      }); //

      //if (currentImage.length == 0) {
      //  const res = await axios.get(`/image/${imageId}`);
      //  const _images = [...images];
      //  currentImage = { ...res.data };
      //  _images[imageIndex] = currentImage;
      //  setImages(_images);
      //} else {
      //  currentImage = currentImage[0];
      //}
      setImage(currentImage[0]);
      setImageIndex(imageIndex);
      //if (set) {
      //  setImage(currentImage);
      //  setImageIndex(imageIndex);
      //}
    } catch (err) {
      alertCallback('An error occured while fetching image');
      console.error(err);
    }
  };

  const getNextImage = () => {
    if (imageIndex < 0) return null;
    if (imageIndex === images.length - 1) return images[0].id;
    return images[imageIndex + 1].id;
  };

  const goToNextImage = () => {
    let nextImageIndex = imageIndex + 1;
    if (imageIndex === images.length - 1) nextImageIndex = 0;
    getImage(images[nextImageIndex].id, (message: string) => {
      return null;
    });
    return null;
  };

  const goToPrevImage = () => {
    if (imageIndex < 0) return null;
    let prevImageIndex = imageIndex - 1;
    if (imageIndex === 0) prevImageIndex = images.length - 1;
    getImage(images[prevImageIndex].id, (message: string) => {
      return null;
    });
    return null;
  };

  const setSpecificImage = (image: any) => {
    if (!sequence) return null;
    getImage(image.id, (message: string) => {
      return null;
    });
    return null;
  };

  const setMainImage = () => {
    if (!sequence) return null;
    getImage(sequence.main_image_id, (message: string) => {
      return null;
    });
    return null;
  };

  const getPreviousImage = () => {
    if (imageIndex < 0) return null;
    if (imageIndex === 0) return images[images.length - 1].id;
    return images[imageIndex - 1].id;
  };

  const value = {
    sequences,
    sequence,
    getSequence,
    getNextSequence,
    getNextIncompleteSequence,
    getPreviousSequence,
    images,
    image,
    getImage,
    getNextImage,
    getPreviousImage,
    imageIndex,
    getSequences,
    getImages,
    sequenceIndex,
    goToNextImage,
    goToPrevImage,
    setMainImage,
    setSpecificImage,
    deleteSequence,
  };

  return <SequencesContext.Provider value={value}>{children}</SequencesContext.Provider>;
};
