import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import 'chart.js/auto';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProgressCard from '../components/Dashboard/ProgressCard';
import RecentEvents from '../components/Dashboard/RecentEvents';
import TimeCard from '../components/Dashboard/TimeCard';
import UserProgress from '../components/Dashboard/UserProgress';
import ProjectHeader from '../components/Projects/ProjectHeader';
import { CommonContext } from '../context/CommonContext';
import { ProjectsContext } from '../context/ProjectsContext';

const ProjectDashboard = () => {
  const { id } = useParams();

  const { project, getProject, getDashboard, dashboard } = useContext(ProjectsContext);
  const { setLoading, setAlertMessage } = useContext(CommonContext);

  useEffect(() => {
    getProject(Number(id));
    getDashboard(Number(id), setAlertMessage);
  }, []);

  useEffect(() => {
    if (project && dashboard) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [project, dashboard]);

  const updateDashboard = () => {
    setLoading(true);
    getDashboard(Number(id), setAlertMessage);
  };

  if (!project || !dashboard) {
    return null;
  }

  const { annotations, expected_annotations, time, users, sequences, latest_updates } = dashboard;

  return (
    <>
      <ProjectHeader dashboard={true} />
      <Box p={2} pl={3}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ProgressCard annotationsCount={annotations} expectedAnnotationsCount={expected_annotations} />
              </Grid>
              <Grid item xs={6}>
                <TimeCard time={time} />
              </Grid>
              <Grid item xs={12}>
                <UserProgress users={users} sequences={sequences} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <RecentEvents latestUpdates={latest_updates} updateDashboard={updateDashboard} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ProjectDashboard;
