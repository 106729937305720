import { ArrowBack, Dashboard, Delete, FormatListBulleted, Lock, LockOpen } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';

const UserProjectAccessTable = () => {
  const { setLoading, setAlertMessage, setLoadingText } = useContext(CommonContext);
  const { projectAccess, updateUserProjectAccess } = useContext(ProjectsContext);
  
  const handleAccessCheckboxClick = (row:any) => {      
    row.access = !row.access;    
    setLoading(true);    
    setLoadingText('Updating project access');                                
    setTimeout(()=>{      
      updateUserProjectAccess(row);      
      setLoadingText('');      
      setLoading(false);      
    }, 1000);
  };

  const columns: GridColDef[] = [    
    { 
      field: 'username', 
      headerName: 'Username', 
      width: 130,
      valueGetter: (params: GridValueGetterParams) => {                  
          return params.row.user.username;
      }, 
    },
    { 
      field: 'role', 
      headerName: 'Role', 
      width: 130,
      valueGetter: (params: GridValueGetterParams) => {        
          return params.row.user.role;
      },
    },
    {
      field: 'access',
      headerName: 'Access',
      type: 'boolean',
      width: 90,
      valueGetter: (params: GridValueGetterParams) => {
          return params.row.access;
      },
      renderCell: (params: GridRenderCellParams) => {
          const access = params.row.access;
          return <Checkbox checked={access} onClick={()=>{handleAccessCheckboxClick(params.row);}}/>;
      },
    }
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        getRowId={(row) => {          
          return row.user.id;
        }}
        autoHeight={true}
        rows={projectAccess.access}
        columns={columns}
        pageSizeOptions={[5, 10]}        
        density="compact"
        disableRowSelectionOnClick={true}
      />
    </div>
  );
};

export default UserProjectAccessTable;