import { SequencesContext } from '../../context/SequencesContext';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import { Box, Card, Typography } from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
const SequenceInfoBox = ({ handleImageClickGalleryView }: any) => {
  const { sequence } = useContext(SequencesContext);
  const { isAnnotator } = useContext(AuthContext);
  return (
    <Box m={1}>
      <Typography variant="h6">Additional clinical information about the case provided by the neurosurgeon:</Typography>
      {sequence?.info.split(',').map((info: string, idx: number) => {
        if (!(info === '')) {
          const infoKey = info.split(':')[0];
          const infoValue = info.split(':')[1];
          const infoBox = (
            <Box
              key={sequence.id + '_' + idx}
              sx={{ marginTop: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
            >
              <Box
                key={sequence.id + '_' + idx}
                sx={{ marginTop: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
              >
                <Typography sx={{ textAlign: 'center' }}>{infoKey}:</Typography>
                <Typography sx={{ textAlign: 'center' }}>{infoValue}</Typography>
              </Box>
            </Box>
          );

          if (info.split(':')[0].trim() !== 'Suspected clinical diagnosis') {
            return infoBox;
          } else {
            return isAnnotator() ? infoBox : null;
          }
        }
      })}
    </Box>
  );
};
export default SequenceInfoBox;
