import { Add } from '@mui/icons-material';
import { Box, Button, Fab, Grid, Typography, Switch, Checkbox, Slider, Stack } from '@mui/material';
import { useContext } from 'react';
import ProjectCard from '../components/Projects/ProjectCard';
import { AuthContext } from '../context/AuthContext';
import { ProjectsContext } from '../context/ProjectsContext';

const ProjectsGallery = () => {
  const { recentProjects, setOpenAddDialog, setOpenAllDialog, getProjects } = useContext(ProjectsContext);
  const { user } = useContext(AuthContext);

  const { role } = user;

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleClickOpenAllDialog = () => {
    setOpenAllDialog(true);
  };

  return (
    <>
      <Box p={5}>
        <Box
          sx={{
            margin: 1,
          }}
        >
          {recentProjects.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h4">Recent Projects</Typography>
              <Button onClick={handleClickOpenAllDialog}>Show All</Button>
            </Box>
          ) : (
            <Typography variant="h6">No recent projects. Please create a new project.</Typography>
          )}
        </Box>
        <Grid container spacing={5}>
          {recentProjects.map((project: any, index: number) => {
            return (
              <Grid key={index} item lg={4} md={6} sm={12}>
                <ProjectCard project={project} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {role === 'admin' && (
        <Fab
          color="secondary"
          aria-label="add"
          sx={{
            position: 'absolute',
            bottom: 50,
            right: 50,
          }}
          onClick={handleClickOpenAddDialog}
        >
          <Add />
        </Fab>
      )}
    </>
  );
};

export default ProjectsGallery;
