import { ArrowBack, CheckCircle } from '@mui/icons-material';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import M3i from '../../assets/m3iIcon.png';
import { AuthContext } from '../../context/AuthContext';
import { CommonContext } from '../../context/CommonContext';
import { SequencesContext } from '../../context/SequencesContext';
import Stopwatch from './Stopwatch';
import AlertDialog from '../Common/AlertDialog';

const Header = () => {
  const navigate = useNavigate();
  const { id, stageId, sequenceId } = useParams();
  const [openLockAlertDialog, setOpenLockAlertDialog] = useState(false);
  const [lockAlertDialogText, setLockAlertDialogText] = useState('');
  const { sequence, sequences, getSequence, getSequences, sequenceIndex } = useContext(SequencesContext);
  const { setAlertMessage } = useContext(CommonContext);
  const { user, isAnnotator, isAdmin, isAnalyst } = useContext(AuthContext);

  useEffect(() => {
    getSequences(Number(stageId), user.id, setAlertMessage);
    getSequence(Number(sequenceId));
  }, [sequenceId]);

  useEffect(() => {
    getSequence(Number(sequenceId));
  }, [sequences]);


  if (!sequence) return null;

  const goToProject = () => {
    navigate(`/projects/${id}`);
  };
  const handleCloseLockAlertDialog = () => {
    setOpenLockAlertDialog(false);
  };

  const handleGoToProjectClick = () => {
    setOpenLockAlertDialog(true);
    setLockAlertDialogText(
      'Please confirm you want to perform this action. This will remove any progress you might have made as part of this case.'
    );
  };
  return (
    <AppBar
      position="fixed"
      elevation={3}
      sx={{
        backgroundColor: 'background.paper',
        borderColor: 'primary.main',
        borderBottom: '2px double',
      }}
    >
      <Toolbar
        sx={{
          height: 50,
          // minHeight: 40,
        }}
        variant="dense"
      >
        <Box
          sx={{
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={goToProject}
        >
          <img src={M3i} alt="Home" title="Home" height="100%" />
        </Box>
        <Box ml={2} sx={{ flexGrow: 0.5 }}>
          <Button
            size="small"
            startIcon={<ArrowBack />}
            id="admin-panel"
            color="primary"
            onClick={handleGoToProjectClick}
          >
            Back to project
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex' }}>
          {
            isAnnotator() || isAdmin() || isAnalyst()?  <Typography>Case {sequence.id}</Typography>: <Typography>Case {sequenceIndex + 1} </Typography>
            //<Typography>Case {sequence.id}</Typography>
          }
          {sequence.completed && <CheckCircle sx={{ ml: 1 }} color="primary" />}
        </Box>
        {isAnnotator() ? null : (
          <Box>
            <Stopwatch />
          </Box>
        )}

        {/* <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <IconButton aria-label="previous" color="primary" onClick={goToPreviousSequence}>
            <SkipPrevious />
          </IconButton>
          <Typography>{sequence.name}</Typography>
          <IconButton aria-label="previous" color="primary" onClick={goToNextSequence}>
            <SkipNext />
          </IconButton>
        </Box> */}
      </Toolbar>
      <AlertDialog
        open={openLockAlertDialog}
        handleClose={handleCloseLockAlertDialog}
        action={goToProject}
        title="Alert"
        text={lockAlertDialogText}
      />
    </AppBar>
  );
};

export default Header;
