import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectHeader from '../components/Projects/ProjectHeader';
import StageProgress from '../components/Projects/StageProgress';
import SequencesList from '../components/Sequences/SequencesList';
import { CommonContext } from '../context/CommonContext';
import { ProjectsContext } from '../context/ProjectsContext';

const Project = () => {
  const { id } = useParams();

  const { project, stages, getProject, getStages } = useContext(ProjectsContext);
  const { setLoading, setAlertMessage } = useContext(CommonContext);

  useEffect(() => {
    getProject(Number(id));
    getStages(Number(id), setAlertMessage);
  }, [id]);

  useEffect(() => {
    if (!project) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [project]);

  if (!project || !stages) {
    return null;
  }
  return (
    <Box>
      <ProjectHeader />
      {/* <ProjectStages /> */}
      <StageProgress />
      <SequencesList />
    </Box>
  );
};

export default Project;
