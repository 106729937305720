import { Box, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';
import UserProjectAccessTable from './UserProjectAccessTable';
const otherAtt = { directory: '', webkitdirectory: '' };

const ProjectAccess = () => {
  const { setLoading, setAlertMessage, setLoadingText } = useContext(CommonContext);
  const { openProjectAccessDialog, setOpenProjectAccessDialog } = useContext(ProjectsContext);
  
  const onSubmit = async (_e: any) => {
    handleClose();
    setLoading(true);
    setLoadingText('Updating project access');                            
    setLoadingText('');
    setLoading(false);
  };

  const handleClose = () => {
    setOpenProjectAccessDialog(false);
  };


  return (
    <Dialog
      open={openProjectAccessDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">Project access</DialogTitle>
      <DialogContent>        
        <UserProjectAccessTable/>                  
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>        
      </DialogActions>
    </Dialog>
  );
};

export default ProjectAccess;
