import { Person2, Refresh } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import COLORS from '../../constants/colors.json';
import { getLastActive } from '../../utils/datetime';

const RecentEvents = ({ latestUpdates, updateDashboard }: any) => {
  const theme = useTheme();

  const getAvatarBgColor = (userId: number) => {
    return COLORS[userId % COLORS.length];
  };

  return (
    <>
      <Card sx={{ height: '75vh', overflowY: 'auto' }}>
        <Box
          m={1}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ mt: 1 }}>Recent updates</Typography>
          <IconButton color="secondary" aria-label="refresh" size="small" onClick={updateDashboard}>
            <Refresh />
          </IconButton>
        </Box>
        <Box>
          <List sx={{ width: '100%' }}>
            <TransitionGroup>
              {latestUpdates.map((update: any, index: number) => (
                <Collapse key={index}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Person2 />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={update.user_name + ': ' + update.sequence_name}
                      secondary={getLastActive(update.last_updated)}
                    />
                  </ListItem>
                </Collapse>
              ))}
            </TransitionGroup>
          </List>
        </Box>
      </Card>
    </>
  );
};

export default RecentEvents;
