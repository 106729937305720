import { isEqual } from 'lodash';

export const CVATtoRoundRobinlabelConverter: any = {
  adenoid: 'adenoid_structures',
  necrotic: 'necrosis',
  vessels: 'vessels',
  //fibroid: 'fibrous_structures',
  hypercellular: 'hypercellularity',
  giantOne: 'giant_structures',
  giantMany: 'giant_structures',
};
export const omitLabels = ['distortion', 'contrast', 'dSNR', 'non-diagnostic', 'diagnostic', 'ROI', 'good', 'bad'];
export const annotationMetaData: any = {
  necrosis: {
    type: 'tree',
    label: 'necrosis',
    color: 'rgba(153, 102, 255, 1)',
    options: [
      {
        id: 0,
        value: 0,
        label: 'Not visibile',
      },
      {
        id: 1,
        value: 1,
        label: 'Visible',
        action: {
          description: 'Please mark the structure on one representative area in the image',
          type: 'annotation',
          tool: 'polygon',
        },
      },
    ],
  },
  hypercellularity: {
    type: 'tree',
    label: 'hypercellularity',
    color: 'rgba(255, 99, 132, 1)',
    options: [
      {
        id: 0,
        value: 0,
        label: 'Not visibile',
      },
      {
        id: 1,
        value: 1,
        label: 'Visible',
        action: {
          description: 'Please mark the structure on one representative area in the image',
          type: 'annotation',
          tool: 'polygon',
        },
      },
    ],
  },
  giant_structures: {
    type: 'tree',
    label: 'giant cellular structures',
    color: 'rgba(54, 162, 235, 1)',
    options: [
      {
        id: 0,
        value: 0,
        label: 'Not visibile',
      },
      {
        id: 1,
        value: 1,
        label: 'Visible',
        action: {
          description: 'Please mark the structure on one representative area in the image',
          type: 'annotation',
          tool: 'polygon',
        },
      },
    ],
  },
  vessels: {
    type: 'tree',
    label: 'vessels',
    color: 'rgba(127, 255, 0, 1)',
    options: [
      {
        id: 0,
        value: 0,
        label: 'Not visibile',
      },
      {
        id: 1,
        value: 1,
        label: 'Visible',
        action: {
          description: 'Please mark the structure on one representative area in the image',
          type: 'annotation',
          tool: 'polygon',
        },
      },
    ],
  },
};
const groundTruthAnnotationTemplateQuestionnaire: questionnaire = {
  id: 1,
  type: 'questionnaire',
  completed: false,
  description: 'Categorize this case based on all images in the sequence',
  template: [
    {
      id: 0,
      label:
        'Please indicate whether you consider this image diagnostically usable or unusable due to technical artifacts.',
      selected: null,
      options: [
        {
          id: 'artificial',
          label: 'unusable due to technical artifacts',
          terminal: true,
        },
        {
          id: 'diagnostic',
          label: 'diagnostically usable',
          terminal: false,
        },
      ],
      type: 'tree',
      completed: false,
      condition: null,
      display_info: false,
    },
    {
      id: 1,
      type: 'radio-grid',
      label:
        'Please grade the presence of the following cellular and tissue features. Please indicate the maximum expression level of each criterion below across the entire case.',
      rows: [
        {
          id: 'hypercellularity',
          label: 'hypercellularity',
          selected: 0,
          color: 'rgba(255, 99, 132, 1)',
        },
        {
          id: 'giant_structures',
          label: 'giant cellular structures',
          selected: 0,
          color: 'rgba(54, 162, 235, 1)',
        },
        {
          id: 'necrosis',
          label: 'necrosis',
          selected: 0,
          color: 'rgba(153, 102, 255, 1)',
        },
      ],
      columns: [
        {
          value: 0,
          label: 'none',
        },
        {
          value: 1,
          label: 'low',
        },
        {
          value: 2,
          label: 'high',
        },
      ],
      condition: {
        level_id: 0,
        selected: 'diagnostic',
      },
      completed: false,
      display_info: true,
    },
    {
      id: 2,
      type: 'radio-grid',
      label:
        'Please indicate whether the following cellular and tissue features are present or not across the entire case.',
      rows: [
        {
          id: 'vessels',
          label: 'vessels',
          selected: 1,
          color: 'rgba(127, 255, 0, 1)',
        },
      ],
      columns: [
        {
          value: 0,
          label: 'none',
        },
        {
          value: 1,
          label: 'present',
        },
      ],
      condition: {
        level_id: 1,
      },
      completed: false,
      display_info: false,
    },
    {
      id: 3,
      label:
        'Which preliminary conclusion do you draw based on the criteria above? Please select from one of the following options:',
      selected: null,
      options: [
        {
          id: 0,
          label: 'faint sodium fluorescein signal (suggesting absence of tumor infiltration)',
        },
        {
          id: 1,
          label: 'normal cellularity, no evidence of tumor tissue',
        },
        {
          id: 2,
          label: 'slighly elevated cellularity, insufficient to confirm tumor infiltration',
        },
        {
          id: 3,
          label:
            'significantly elevated cellularity, cytological atypia and/or disordered architecture, most likely due to tumor infiltration',
        },
        {
          id: 4,
          label: 'marked tumor infiltration',
        },
        {
          id: 5,
          label: 'solid tumor or clear predominance of tumor cells',
        },
      ],
      type: 'tree',
      completed: false,
      condition: {
        level_id: 2,
      },
      display_info: false,
    },
    {
      id: 4,
      label: 'Indicate the type of tissue in this case',
      selected: null,
      options: [
        {
          id: 'healthy',
          label: 'normal tissue',
          terminal: true,
        },
        {
          id: 'lesional',
          label: 'lesional tissue',
          terminal: false,
        },
      ],
      type: 'tree',
      completed: false,
      condition: {
        level_id: 3,
      },
      display_info: false,
    },
    {
      id: 5,
      label:
        'Taking into account the clinical information above, please try to further categorize the benign/malignant tissue',
      selected: null,
      options: [
        {
          id: 'benign',
          label: 'benign',
          terminal: false,
        },
        {
          id: 'malignant',
          label: 'malignant',
          terminal: false,
        },
      ],
      type: 'tree',
      completed: false,
      condition: {
        level_id: 4,
        selected: 'lesional',
      },
      display_info: false,
    },
  ],
};

export interface questionnaire {
  id: number;
  type: string;
  completed: boolean;
  description: string;
  template: question[];
}

interface option {
  id: number | string;
  label: string;
  terminal?: boolean;
}

interface column {
  value: number;
  label: string;
}

interface row {
  id: number | string;
  label: string;
  terminal?: boolean;
  selected: any;
  color: string;
}

interface question {
  id: number;
  label: string;
  condition: any;
  selected?: number | string | null;
  options?: option[];
  rows?: row[];
  columns?: column[];
  type: string;
  completed: boolean;
  display_info: boolean;
}

const checkIfNotAnswered = (question: question) => {
  if (question.selected != undefined) {
    if (question.selected === null) return true;
  } else if (question.rows != undefined) {
    for (const row of question.rows) {
      if (row.selected === null) return true;
    }
  }
  return false;
};

const checkIfAllNotAnswered = (question: question) => {
  if (question.selected != undefined) {
    if (question.selected === '') return true;
  } else if (question.rows != undefined) {
    for (const row of question.rows) {
      if (row.selected !== '') return false;
    }
    return true;
  }
};

const checkQuestionnaireConditions = (questions: question[]) => {
  for (let i = 0; i < questions.length; i++) {
    const q: question = questions[i];
    const qTemplate: question = groundTruthAnnotationTemplateQuestionnaire.template[i];
    if (qTemplate.condition === null) continue;
    const condition = qTemplate.condition;
    const conditionQe = questions.find((_q) => _q.id === condition.level_id);
    if (conditionQe === undefined) return ['question id: ' + q.id + ', ERROR: condition question is missing', false];
    if ('answer' in q) {
      if (q.selected === null) {
        if ('selected' in conditionQe) {
          if ('selected' in condition) {
            if (conditionQe.selected === condition.selected)
              return ['question id: ' + q.id + ', ERROR: missing answer for this question', false];
          } else {
            if (conditionQe.selected !== '')
              return ['question id: ' + q.id + ', ERROR: missing answer for this question', false];
          }
        } else if ('answers' in conditionQe) {
          if (!checkIfNotAnswered(conditionQe))
            return ['question id: ' + q.id + ', ERROR: missing answer for this question', false];
        }
      } else {
        if ('selected' in condition) {
          if (!(conditionQe.selected === condition.selected))
            return ['question id: ' + q.id + ', ERROR: incorrect answer selected in conditional question', false];
        } else {
          if (checkIfNotAnswered(conditionQe))
            return ['question id: ' + q.id + ', ERROR: missing answers in conditional question', false];
        }
      }
    } else if ('rows' in q) {
      const allAnswered = !checkIfNotAnswered(q);
      let missingAnswers = false;
      if (!allAnswered) {
        if (!checkIfAllNotAnswered(q)) {
          missingAnswers = true;
        }
      }

      //if (missingAnswers) return ['question id: ' + q.id + ', ERROR: missing answers in at least one question', false];

      if (allAnswered) {
        if ('selected' in condition) {
          if (!(conditionQe.selected === condition.selected))
            return ['question id: ' + q.id + ', ERROR: incorrect answer selected in conditional question', false];
        } else {
          if (checkIfNotAnswered(conditionQe))
            return ['question id: ' + q.id + ', ERROR: missing answers in conditional question', false];
        }
      } else {
        if ('selected' in conditionQe) {
          if ('selected' in condition) {
            if (conditionQe.selected === condition.selected) {
              if (missingAnswers)
                return ['question id: ' + q.id + ', ERROR: missing answers in at least one question', false];
            }
            //return ['question id: ' + q.id + ' ERROR: missing answers for these questions', false];
          } else {
            if (conditionQe.selected !== '')
              return ['question id: ' + q.id + ', ERROR: missing answer for this question', false];
          }
        } else if ('rows' in conditionQe) {
          if (!checkIfNotAnswered(conditionQe))
            return ['question id: ' + q.id + ', ERROR: missing answers for these questions', false];
        }
      }
    }
  }
  return ['', true];
};

const checkForValidAnswers = (questions: question[]) => {
  for (let i = 0; i < questions.length; i++) {
    const q: question = questions[i];
    if (q.selected != undefined) {
      if (q.selected === null) continue;
      const options = q.options?.find((possibleAnswer) => possibleAnswer.id == q.selected);
      if (options === undefined) return ['question id: ' + q.id + ', ERROR: missmatch id in answer and option', false];
    } else if (q.rows != undefined) {
      for (const row of q.rows) {
        if (row.selected === null) continue;
        const options = q.columns?.find((column) => column.value == row.selected);
        if (options === undefined)
          return ['question id: ' + q.id + ', ERROR: missmatch id in answers and option', false];
      }
    }
  }
  return ['', true];
};

const checkQuestionnareEntry = (questions: question[]) => {
  for (let i = 0; i < questions.length; i++) {
    const q: question = questions[i];
    const qTemplate: question = groundTruthAnnotationTemplateQuestionnaire.template[i];
    if (q.id !== qTemplate.id) return ['question id: ' + q.id + ', ERROR: id missmatch', false];
    if (q.label !== qTemplate.label) return ['question id: ' + q.id + ', ERROR: question missmatch', false];
    if (!isEqual(q.options, qTemplate.options)) return ['question id: ' + q.id + ', ERROR: options missmatch', false];
  }
  return ['', true];
};

export const checkQuestionnare = (questionnaire: questionnaire) => {
  if (!('id' in questionnaire)) return ['missing id in questionnaire', false];
  if (!('type' in questionnaire)) return ['missing type in questionnaire', false];
  if (!('completed' in questionnaire)) return ['missing completed in questionnaire', false];
  if (!('description' in questionnaire)) return ['missing description in questionnaire', false];
  if (!('template' in questionnaire)) return ['missing template in questionnaire', false];

  if (questionnaire.id !== groundTruthAnnotationTemplateQuestionnaire.id)
    return ['missmatch id of questionnaire', false];
  if (questionnaire.completed !== groundTruthAnnotationTemplateQuestionnaire.completed)
    return ['completed should be false', false];
  if (questionnaire.description !== groundTruthAnnotationTemplateQuestionnaire.description)
    return ['missmatch description of questionnaire', false];

  const template: question[] = questionnaire.template;

  for (let i = 0; i < template.length; i++) {
    const q: question = template[i];
    if (!('id' in q)) return ['missing id in one of the questions', false];
    if (!('label' in q)) return ['question id: ' + q.id + ', ERROR: missing label entry', false];
    if (!('selected' in q || 'rows' in q))
      return ['question id: ' + q.id + ', ERROR: missing selected or rows entry', false];
    if (!('type' in q)) return ['question id: ' + q.id + ', ERROR: missing type entry', false];
    if (!('display_info' in q)) return ['question id: ' + q.id + ', ERROR: missing display_info entry', false];
    if (q.options != undefined) {
      for (const p of q.options) {
        if (!('id' in p)) return ['question id: ' + q.id + ', ERROR: missing id in one of the options', false];
        if (!('label' in p)) return ['question id: ' + q.id + ', ERROR:  missing label in one of the options', false];
      }
    }
  }

  let check = checkQuestionnareEntry(template);
  if (!check[1]) return check;
  check = checkForValidAnswers(template);
  if (!check[1]) return check;
  check = checkQuestionnaireConditions(template);
  if (!check[1]) return check;

  return ['', true];
};
