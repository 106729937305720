import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';
import { SequencesContext } from '../../context/SequencesContext';
import SequenceCard from './SequenceCard';

const SequencesList = () => {
  const { currentStage } = useContext(ProjectsContext);
  const { sequences, getSequences } = useContext(SequencesContext);
  const { setAlertMessage, setLoading } = useContext(CommonContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!currentStage) {
      setLoading(true);
      return;
    }
    setLoading(false);
    getSequences(currentStage.id, user.id, setAlertMessage);
  }, [currentStage]);

  if (!currentStage) return null;
  return (
    <Box
      sx={{
        width: '100%',
      }}
      p={2}
      pl={3}
    >
      {sequences.map((sequence: any, index: number) => (
        <Box key={sequence.id} my={1}>
          <SequenceCard sequence={sequence} stage={currentStage} index={index} />
        </Box>
      ))}
    </Box>
  );
};

export default SequencesList;
