import { Box, Button, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnnotationContext } from '../../context/AnnotationContext';
import { SequencesContext } from '../../context/SequencesContext';
import { GroundTruthAnnotationContext } from '../../context/GroundTruthAnnotationContext';
import { CommonContext } from '../../context/CommonContext';
import Questionnaire from './Questionnaire';
import { template } from 'lodash';

const GroundTruthContainer = () => {
  const navigate = useNavigate();
  const { id, stageId, sequenceId } = useParams();
  const [tempGroundTruthStep, setTempGroundTruthStep] = useState<any>();

  const {
    currentGroundTruthAction,
    setCurrentGroundTruthAction,
    currentGroundTruthAnnotation,
    groundTruthAnnotationExists,
    groundTruthAnnotation,
    getGroundTruth,
    currentGroundTruthAnnotationStep,
    currentGroundTruthAnnotationStepIndex,
    tempUpdateGroundTruthAnnotation,
    getPreviousGroundTruthAnnotationStep,
    getNextGroundTruthAnnotationStep,
    groundTruthAnnotationLength,
    updateGroundTruthAnnotation,
    getAssociatedPolygonsWithLevel,
  } = useContext(GroundTruthAnnotationContext);
  const { setAlertMessage } = useContext(CommonContext);
  const { getNextIncompleteSequence, getNextSequence, sequence, setMainImage, sequences } =
    useContext(SequencesContext);
  const annotationContainerBoxRef = useRef<any>(null);
  const annotationButtonsBoxRef = useRef<any>(null);

  useEffect(() => {
    setTempGroundTruthStep(currentGroundTruthAnnotationStep);
  }, [currentGroundTruthAnnotationStep]);

  useEffect(() => {
    getGroundTruth(Number(stageId), Number(sequenceId), setAlertMessage);
  }, [stageId, sequenceId]);

  const handleTempAnnotationStepUpdate = (updatedGroundTruthAnnotationStep: any) => {
    let completed = true;
    for (const template of updatedGroundTruthAnnotationStep.template) {
      //if (template.selected === null) {
      if (template.selected === 0) {
        template.annotoriousFormats = null;
        template.coordinates = null;
        completed = false;
      } else if (template.selected === 1) {
        if (!template.annotoriousFormats) {
          completed = false;
        }
      } else if (template.selected === null) {
        if (!template.annotoriousFormats) {
          completed = false;
        }
      }
    }

    if (updatedGroundTruthAnnotationStep.type == 'annotation') {
      updatedGroundTruthAnnotationStep.completed = completed;
    }

    tempUpdateGroundTruthAnnotation(updatedGroundTruthAnnotationStep);
  };

  const handleUpdateGroundTruthAnnotation = () => {
    if (currentGroundTruthAnnotationStepIndex === groundTruthAnnotationLength - 1) {
      updateGroundTruthAnnotation(setAlertMessage);
      nextSequence();
    } else {
      getNextGroundTruthAnnotationStep();
      if (currentGroundTruthAnnotationStepIndex === 0) {
        //navigate(`/projects/${id}/stages/${stageId}/sequences/${sequence.id}/images/${sequence.main_image_id}`);
        setMainImage();
      }
    }
  };
  const transformConditionalLevels = (newAnnotation: any, updatedLevel: any) => {
    for (let i = 0; i < newAnnotation.length; i++) {
      if (!newAnnotation[i].condition) continue;
      const condition = newAnnotation[i].condition;
      const conditionQuestion = newAnnotation[condition.level_id];
      if (!condition.selected) {
        let completed = true;
        if (conditionQuestion.rows) {
          for (const row of conditionQuestion.rows) {
            if (row.selected === null) completed = false;
          }
        } else {
          completed = conditionQuestion.selected != null;
        }
        if (!completed) {
          newAnnotation[i].completed = false;
          newAnnotation[i].selected = null;
          if (newAnnotation[i].rows) {
            for (const row of newAnnotation[i].rows) {
              row.selected = null;
              row.completed = false;
              // delete associated polygons
            }
          }
        }
      } else {
        if (!(conditionQuestion.selected === condition.selected)) {
          newAnnotation[i].completed = false;
          newAnnotation[i].selected = null;
          if (newAnnotation[i].rows) {
            for (const row of newAnnotation[i].rows) {
              row.selected = null;
              row.completed = false;
              // delete associated polygons
            }
          }
        }
      }
    }
    return newAnnotation;
  };

  const renderAnnotationStep = () => {
    if (tempGroundTruthStep) {
      return (
        <Questionnaire
          questionnaireTemplate={tempGroundTruthStep}
          handleAnnotationUpdate={handleTempAnnotationStepUpdate}
          setCurrentAction={setCurrentGroundTruthAction}
          currentAnnotation={currentGroundTruthAnnotation}
          annotationExists={groundTruthAnnotationExists}
          questionnaireType={'ground_truth_annotation'}
          resetLowerLevels={transformConditionalLevels}
          getAssociatedPolygonsWithLevel={getAssociatedPolygonsWithLevel}
          currentAction={currentGroundTruthAction}
        />
      );
    } else {
      return null;
    }
  };

  const nextSequence = () => {
    //navigate(`/projects/${id}`);
    const nextSequence = getNextIncompleteSequence();
    if (!nextSequence) {
      navigate(`/projects/${id}`);
    } else {
      if (nextSequence.completed) {
        //navigate(`/projects/${id}/stages/${stageId}/sequences/${nextSequence.id}/images/${nextSequence.main_image_id}`);
        setMainImage();
      } else {
        navigate(`/projects/${id}/stages/${stageId}/sequences/${nextSequence.id}/images`);
      }
    }
  };
  return (
    <Paper
      sx={{
        height: '90vh',
        overflowY: 'auto',
      }}
      elevation={3}
    >
      <Box m={1} ref={annotationContainerBoxRef}>
        {renderAnnotationStep()}
        <Box m={1} ref={annotationButtonsBoxRef}>
          {groundTruthAnnotationExists ? (
            <Box sx={{ display: 'flex' }}>
              <Typography typography="body2">
                Annotation has been locked and completed. Please navigate to the next case.
              </Typography>
              <Button color="success" onClick={nextSequence} sx={{ ml: 'auto' }} variant="contained">
                Next case
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                {currentGroundTruthAnnotationStepIndex > 0 && (
                  <Button onClick={getPreviousGroundTruthAnnotationStep}>Back</Button>
                )}
              </Box>
              <Box>
                {tempGroundTruthStep?.completed && (
                  <Button color="success" onClick={handleUpdateGroundTruthAnnotation}>
                    {currentGroundTruthAnnotationStepIndex === groundTruthAnnotationLength - 1
                      ? 'Complete'
                      : 'Continue'}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default GroundTruthContainer;
