import { PollOutlined } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, Typography, useTheme } from '@mui/material';

const ProgressCard = ({ annotationsCount, expectedAnnotationsCount }: any) => {
  const theme = useTheme();

  const progressPercentage = () => {
    return Math.round((annotationsCount / expectedAnnotationsCount) * 100);
  };

  return (
    <>
      <Card sx={{ display: 'flex', bgcolor: theme.palette.primary.light }}>
        <Box sx={{ marginX: 'auto', textAlign: 'center', marginTop: 1, color: 'black' }}>
          <CardContent>
            <Avatar
              sx={{
                bgcolor: 'rgb(255, 255, 255, 0.4)',
                marginX: 'auto',
                marginBottom: 2,
                width: 55,
                height: 55,
              }}
            >
              <PollOutlined />
            </Avatar>
            <Box>
              <Typography variant="h4">{progressPercentage()}%</Typography>
              <Typography variant="body2">Annotations completed</Typography>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default ProgressCard;
