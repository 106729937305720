import { Backdrop, CircularProgress, Typography, Box } from '@mui/material';
import { useContext } from 'react';
import { CommonContext } from '../../context/CommonContext';

const Loading = () => {
  const { loading, loadingText } = useContext(CommonContext);

  return (
    <Backdrop
      sx={{
        zIndex: 2001,
      }}
      open={loading}
    >
      <CircularProgress />
      <Typography ml={2} variant="h5">
        {loadingText}
      </Typography>
    </Backdrop>
  );
};

export default Loading;
