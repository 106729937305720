import { DateTime } from 'luxon';

const units: Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

export const formatDate = (isoDate: any) => {
  if (!isoDate) {
    return '';
  }
  const dt = DateTime.fromISO(isoDate);
  return dt.setLocale('de').toLocaleString();
};

export const getLastActive = (isoDate: any) => {
  if (!isoDate) {
    return '';
  }
  const dt = DateTime.fromISO(isoDate, { zone: 'utc' });

  const diff = dt.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export const getNow = () => {
  return DateTime.now();
};

export const formatTimeHrMin = (time: number) => {
  const milliseconds = Math.floor((time % 1000) / 100),
    seconds = Math.floor((time / 1000) % 60),
    minutes = Math.floor((time / (1000 * 60)) % 60),
    hours = Math.floor((time / (1000 * 60 * 60)) % 24);

  const hoursString = hours < 1 ? null : hours + 'hr';
  const minutesString = minutes + 'min';

  if (hoursString) return hoursString + ' ' + minutesString;
  if (minutes) return minutesString;
  return 'Not started';
};

export const formatTimeMinSec = (time: number) => {
  const milliseconds = Math.floor((time % 1000) / 100),
    seconds = Math.floor((time / 1000) % 60),
    minutes = Math.floor((time / (1000 * 60)) % 60),
    hours = Math.floor((time / (1000 * 60 * 60)) % 24);

  const minutesString = minutes + 'min';
  const secondsString = seconds + 'sec';

  if (minutes) return minutesString + ' ' + secondsString;
  if (seconds) return secondsString;
  return 'Not started';
};
