import { SequencesContext } from '../../context/SequencesContext';
import { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
const GalleryView = ({ handleImageClickGalleryView }: any) => {
  const { images } = useContext(SequencesContext);
  const handleClickImageListItem = (image: any) => {
    handleImageClickGalleryView(image);
  };
  return (
    <>
      <ImageList sx={{ height: '-webkit-fill-available' }} cols={3}>
        {images.map((image: any, idx: number) => {
          return (
            <ImageListItem
              onClick={() => {
                handleClickImageListItem(image);
              }}
              sx={{
                display: 'flex',
                margin: 1,
                //'&:hover': {
                //  border: '1px solid',
                //  borderColor: 'primary.main',
                //  transform: 'scale(1.01)',
                //},
              }}
              key={image.id}
            >
              <Box
                sx={{
                  '&:hover': {
                    border: '1px solid',
                    borderColor: 'primary.main',
                    transform: 'scale(1.01)',
                  },
                }}
              >
                <Typography sx={{ position: 'absolute', top: 8, left: 16, fontSize: 40 }}>{idx + 1}</Typography>
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={image.s3_info.presigned_url}
                  srcSet={image.s3_info.presigned_url}
                  alt={image.id}
                  loading="lazy"
                />
              </Box>
            </ImageListItem>
          );
        })}
      </ImageList>
    </>
  );
};
export default GalleryView;
