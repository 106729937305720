import { Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import QuestionnaireGrid from './QuestionnaireGrid';
import QuestionnaireRadio from './QuestionnaireRadio';
import QuestionnaireTree from './QuestionnaireTree';

const Questionnaire = ({
  currentAction,
  questionnaireTemplate,
  handleAnnotationUpdate,
  setCurrentAction,
  currentAnnotation,
  annotationExists,
  questionnaireType,
  resetLowerLevels,
  getAssociatedPolygonsWithLevel,
}: any) => {
  const [tempAnnotation, setTempAnnotation] = useState<any[]>([]);
  useEffect(() => {
    setTempAnnotation(questionnaireTemplate.template);
  }, [questionnaireTemplate]);

  const updateAnnotation = (updatedLevel: any, terminal = false, update = true) => {
    let newAnnotation = [...tempAnnotation];
    const newAnnotationIndex = newAnnotation.findIndex((anno: any) => anno.id === updatedLevel.id);

    if (newAnnotationIndex === newAnnotation.length - 1 && updatedLevel.completed) {
      terminal = true;
    }
    newAnnotation[newAnnotationIndex] = updatedLevel;
    if (questionnaireType === 'user_annotation') {
      if (updatedLevel.type === 'radio-grid') {
        let completed = true;
        for (const row of updatedLevel.rows) {
          if (row.selected === null) completed = false;
        }
        newAnnotation[updatedLevel.id].completed = completed;
      }
    } else {
      if (updatedLevel.type === 'radio-grid') {
        let completed = true;
        for (const row of updatedLevel.rows) {
          if (!row.completed) completed = false;
        }
        newAnnotation[updatedLevel.id].completed = completed;
      }
    }
    newAnnotation = resetLowerLevels(newAnnotation, updatedLevel);
    setTempAnnotation(newAnnotation);
    handleAnnotationUpdate({ ...questionnaireTemplate, template: newAnnotation, completed: terminal });
  };

  const checkLevelCondition = (condition: any) => {
    if (!condition) return true;
    const conditionAnnotation = tempAnnotation.find((annotation: any) => annotation.id === condition.level_id);
    if (!conditionAnnotation || !conditionAnnotation.completed) return false;
    if (condition.selected && conditionAnnotation.selected !== condition.selected) return false;
    return true;
  };
  const getDependantChildren = (level: any) => {
    const dependantChildren = [];
    for (const _level of tempAnnotation) {
      if (!_level.condition) continue;
      if (!(_level.condition.level_id == level.id)) continue;
      dependantChildren.push(_level);
    }
    return dependantChildren;
  };

  const renderAnnotationComponent = (level: any) => {
    if (!checkLevelCondition(level.condition)) return null;
    switch (level.type) {
      case 'tree':
        return (
          <QuestionnaireTree
            level={level}
            updateAnnotation={updateAnnotation}
            setCurrentAction={setCurrentAction}
            currentAnnotation={currentAnnotation}
            annotationExists={annotationExists}
            questionnaireType={questionnaireType}
            getDependantChildren={getDependantChildren}
            getAssociatedPolygonsWithLevel={getAssociatedPolygonsWithLevel}
            currentAction={currentAction}
            questionType={questionnaireTemplate.type}
          />
        );
      case 'radio-grid':
        return (
          <QuestionnaireGrid
            level={level}
            updateAnnotation={updateAnnotation}
            questionnaireType={questionnaireType}
            getAssociatedPolygonsWithLevel={getAssociatedPolygonsWithLevel}
          />
        );
      case 'radio':
        return (
          <QuestionnaireRadio level={level} updateAnnotation={updateAnnotation} questionnaireType={questionnaireType} />
        );
      default:
        return <Typography sx={{ margin: 1 }}>Annotation type not recognized</Typography>;
    }
  };

  return (
    <>
      <Typography
        variant="body2"
        sx={{
          textAlign: 'center',
        }}
      >
        {questionnaireTemplate.description}
      </Typography>
      {tempAnnotation.length === 0 && (
        <Typography variant="caption">
          No further annotation needed. Please click on{' '}
          {questionnaireType === 'user_annotation' ? '"lock and complete"' : 'complete'} before proceeding to the next
          sequence.
        </Typography>
      )}
      {tempAnnotation.map((level: any) => (
        <Fragment key={level.id}>{renderAnnotationComponent(level)}</Fragment>
      ))}
    </>
  );
};

export default Questionnaire;
