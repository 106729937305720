import { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { CommonContext } from '../../context/CommonContext';

const PrivateRouteWrapper = () => {
  const { isAuthenticated, loadUser, loading } = useContext(AuthContext);
  const { setLoading } = useContext(CommonContext);

  useEffect(() => {
    if (loading) {
      setLoading(true);
      loadUser();
    } else {
      setLoading(false);
    }
  }, [loading]);

  if (loading) return null;

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRouteWrapper;
