import { ArrowBack, Dashboard, Delete, FormatListBulleted, Lock, LockOpen } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';
import AlertDialog from '../Common/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import KeyIcon from '@mui/icons-material/Key';
const ProjectHeader = ({ dashboard = false }: any) => {
  const navigate = useNavigate();

  const { isAdmin, isAnalyst } = useContext(AuthContext);
  const { project, openProjectAccessDialog, setOpenProjectAccessDialog, updateProject, setOpenAddCasesDialog, exportProject } = useContext(ProjectsContext);
  const { setLoading, setLoadingText, setAlertMessage, setDeleteCases, deleteCases } = useContext(CommonContext);
  const { locked } = project;

  const [openLockAlertDialog, setOpenLockAlertDialog] = useState(false);
  const [lockAlertDialogText, setLockAlertDialogText] = useState('');

  const navigateToProjects = () => {
    navigate('/projects');
  };

  const navigateToDashboard = () => {
    navigate(`/projects/${project.id}/dashboard`);
  };

  const navigateToSequences = () => {
    navigate(`/projects/${project.id}`);
  };

  const handleUnlockProject = () => {
    setOpenLockAlertDialog(true);
    setLockAlertDialogText(
      'Please confirm you want to unlock this project. This will make the project visible to all users.'
    );
  };

  const handleLockProject = () => {
    setOpenLockAlertDialog(true);
    setLockAlertDialogText(
      'Please confirm you want to lock this project. This will hide the project to non-analyst users and disable annotation.'
    );
  };

  const handleCloseLockAlertDialog = () => {
    setOpenLockAlertDialog(false);
  };

  const updateProjectLock = () => {
    updateProject({ ...project, locked: !locked }, setAlertMessage);
    handleCloseLockAlertDialog();
  };

  const handleAddCases = () => {
    setOpenAddCasesDialog(true);
  };

  const handleDeleteCases = () => {
    setDeleteCases(!deleteCases);
  };

  const handleExport = () => {
    setLoading(true);
    console.log('started export');
    setLoadingText('Exporting project');
    exportProject(project.id, ()=>{
      console.log('done export');
      setLoadingText('');
      setLoading(false);
    });    
  };
  
  const handleAccess = () => {    
    console.log('handleAccess');
    console.log('handleAccess: openProjectAccessDialog: ' + openProjectAccessDialog);
    setOpenProjectAccessDialog(true);
  };

  return (
    <Paper elevation={3}>
      <Box
        sx={{
          width: '100%',
        }}
        p={2}
        pl={3}
      >
        <Grid container>
          <Grid item xs={9}>
            <Button
              size="small"
              startIcon={<ArrowBack />}
              id="admin-panel"
              color="primary"
              onClick={navigateToProjects}
            >
              Back to projects
            </Button>
            <Typography variant="h2">
              {project.name}
              {locked && (
                <Tooltip title="Project is currently locked">
                  <Lock color="secondary" />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body1">{project.info}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                float: 'right',
                margin: 3,
              }}
            >
              {isAnalyst() && (
                <Box>
                  {!dashboard ? (
                    <Button
                      size="small"
                      startIcon={<Dashboard />}
                      id="admin-panel"
                      color="secondary"
                      onClick={navigateToDashboard}
                    >
                      To Dashboard
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      startIcon={<FormatListBulleted />}
                      id="admin-panel"
                      color="secondary"
                      onClick={navigateToSequences}
                    >
                      To Sequences
                    </Button>
                  )}
                </Box>
              )}
              {isAdmin() && (
                <Box>
                  {locked ? (
                    <Button
                      size="small"
                      startIcon={<LockOpen />}
                      id="unlock-project"
                      color="secondary"
                      onClick={handleUnlockProject}
                    >
                      Unlock project
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      startIcon={<Lock />}
                      id="lock-project"
                      color="secondary"
                      onClick={handleLockProject}
                    >
                      Lock project
                    </Button>
                  )}
                </Box>
              )}
              {isAdmin() && (
                <Box>
                  {locked ? null : (
                    <Button size="small" startIcon={<AddIcon />} color="secondary" onClick={handleAddCases}>
                      Add cases
                    </Button>
                  )}
                </Box>
              )}

              {isAdmin() && (
                <Box>
                  {locked ? null : (
                    <Button size="small" startIcon={<DeleteIcon />} color="secondary" onClick={handleDeleteCases}>
                      Delete cases
                    </Button>
                  )}
                </Box>
              )}
              {isAdmin() && (
                <Box>
                  {locked ? null : (
                    <Button size="small" startIcon={<DownloadIcon />} color="secondary" onClick={handleExport}>
                      Export
                    </Button>
                  )}
                </Box>
              )}
              {(isAdmin() || isAnalyst()) && (
                <Box>
                  {locked ? null : (
                    <Button size="small" startIcon={<KeyIcon />} color="secondary" onClick={handleAccess}>
                      Access
                    </Button>
                  )}
                </Box>
              )}

              {/* <Box>
                  <Button
                    size="small"
                    startIcon={<FileDownload />}
                    id="admin-panel"
                    color="secondary"
                    onClick={navigateToProjects}
                  >
                    Export data
                  </Button>
                </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AlertDialog
        open={openLockAlertDialog}
        handleClose={handleCloseLockAlertDialog}
        action={updateProjectLock}
        title="Alert"
        text={lockAlertDialogText}
      />
    </Paper>
  );
};

export default ProjectHeader;
