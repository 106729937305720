import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';

const AlertSnackbar = () => {
  const { alertMessage, setAlertMessage } = useContext(CommonContext);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setAlertMessage('');
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (alertMessage === '') return;
    console.error(alertMessage);
    handleOpenSnackbar();
  }, [alertMessage]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={openSnackbar}
      onClose={handleCloseSnackbar}
      autoHideDuration={10000}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert onClose={handleCloseSnackbar} severity="error">
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
