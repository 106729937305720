import axios from 'axios';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import COLORS from '../constants/colors.json';

export const page1Template = [
  {
    id: 0,
    label: 'Please indicate whether you consider this case diagnostically usable or unusable due to artifacts.',
    type: 'tree',
    condition: null,
    options: [
      {
        id: 'artificial',
        label: 'unusable due to technical artifacts',
        terminal: true,
      },
      {
        id: 'diagnostic',
        label: 'diagnostically usable',
        terminal: false,
      },
    ],
    selected: null,
    completed: false,
    display_info: false,
  },
  {
    id: 1,
    label:
      'Please grade the presence of the following cellular and tissue features. Please indicate the maximum expression level of each criterion below across the entire case.',
    type: 'radio-grid',
    condition: {
      level_id: 0,
      selected: 'diagnostic',
    },
    rows: [
      {
        id: 'hypercellularity',
        label: 'hypercellularity',
        selected: null,
        color: COLORS[0],
      },
      {
        id: 'pleomorphic_cells',
        label: 'pleomorphic cells',
        selected: null,
        color: COLORS[1],
      },
      {
        id: 'necrosis',
        label: 'necrosis',
        selected: null,
        color: COLORS[4],
      },
    ],
    columns: [
      {
        value: 0,
        label: 'none',
      },
      {
        value: 1,
        label: 'low',
      },
      {
        value: 2,
        label: 'high',
      },
    ],
    completed: false,
    display_info: true,
  },
  {
    id: 2,
    label: 'Indicate the presence of vessels across the entire case.',
    type: 'radio-grid',
    condition: {
      level_id: 1,
    },
    rows: [
      {
        id: 'vessels',
        label: 'vessels',
        selected: null,
        color: COLORS[5],
      },
    ],
    columns: [
      {
        value: 0,
        label: 'none',
      },
      {
        value: 1,
        label: 'present',
      },
    ],
    completed: false,
    display_info: false,
  },
  {
    id: 3,
    label:
      'Which preliminary conclusion do you draw based on the criteria above? Please select from one of the following options:',
    type: 'radio',
    condition: {
      level_id: 2,
    },
    options: [
      {
        id: 0,
        label: 'faint sodium fluorescein signal (suggesting absence of tumor infiltration)',
      },
      {
        id: 1,
        label: 'normal cellularity, no evidence of tumor tissue',
      },
      {
        id: 2,
        label: 'slighly elevated cellularity, insufficient to confirm tumor infiltration',
      },
      {
        id: 3,
        label:
          'significantly elevated cellularity, cytological atypia and/or disordered architecture, most likely due to tumor infiltration',
      },
      {
        id: 4,
        label: 'marked tumor infiltration',
      },
      {
        id: 5,
        label: 'solid tumor or clear predominance of tumor cells',
      },
    ],
    selected: null,
    completed: false,
    display_info: false,
  },
  {
    id: 4,
    label: 'Indicate the predominant tissue type in this case',
    type: 'tree',
    condition: {
      level_id: 3,
    },
    options: [
      {
        id: 'healthy',
        label: 'normal tissue',
        terminal: true,
      },
      {
        id: 'lesional',
        label: 'lesional tissue',
        terminal: false,
      },
    ],
    selected: null,
    completed: false,
  },
  {
    id: 5,
    label:
      'Taking into account the clinical information above, please try to further categorize the tissue into whether it is benign or malignant',
    type: 'tree',
    condition: {
      level_id: 4,
      selected: 'lesional',
    },
    options: [
      {
        id: 'benign',
        label: 'benign',
        terminal: false,
      },
      {
        id: 'malignant',
        label: 'malignant',
        terminal: false,
      },
    ],
    selected: null,
    completed: false,
    display_info: false,
  },
];

export const parentTemplate = {
  lockOnCompletion: true,
  completed: false,
  steps: [
    {
      id: 1,
      type: 'questionnaire',
      completed: false,
      template: page1Template,
      description: 'Categorize this case based on all images in the sequence',
    },
    {
      id: 2,
      type: 'annotation',
      template: [],
      styles: ['point'],
      completed: false,
      description:
        'You already indicated that the following cellular and tissue features were visible among the entire case. Now please specify whether those cellular and tissue features are also visible in the current image that is part of this case.',
      mainImage: true,
    },
  ],
};

export const generatePointAnnotationTemplate = (annotationTree: any) => {
  const questionnaireStep = annotationTree.find((step: any) => step.id === 1);
  const annotationStep = annotationTree.find((step: any) => step.id === 2);
  annotationStep.template = [];

  const malignancyCriteriaLevels = questionnaireStep.template.filter((level: any) => level.id === 1 || level.id === 2);

  let lastRowId = null;
  for (const malignancyCriteriaLevel of malignancyCriteriaLevels) {
    if (malignancyCriteriaLevel.type === 'radio-grid') {
      for (const row of malignancyCriteriaLevel.rows) {
        if (row.selected && row.selected !== 0) {
          let condition = null;
          if (lastRowId) {
            condition = {
              level_id: lastRowId,
            };
          }
          annotationStep.template.push({
            type: 'tree',
            id: row.id,
            label: row.label,
            color: row.color,
            options: [
              {
                id: 0,
                value: 0,
                label: 'Not visibile',
              },
              {
                id: 1,
                value: 1,
                label: 'Visible',
                action: {
                  description: 'Please mark the structure on one representative area in the image',
                  type: 'annotation',
                  tool: 'point',
                },
              },
            ],
            selected: null,
            coordinates: [],
            annotoriousFormat: null,
            condition,
            completed: false,
          });
          lastRowId = row.id;
        }
      }
    }
  }
  if (annotationStep.template.length === 0) {
    annotationStep.completed = true;
  } else {
    annotationStep.completed = false;
  }
  return annotationStep;
};

interface AnnotationContextInterface {
  annotation: any;
  getAnnotation: (stageId: number, sequenceId: number, alertCallback: (message: string) => void) => void;
  updateAnnotation: (alertCallback: (message: string) => void) => void;
  currentAnnotationStep: any;
  currentAnnotationStepIndex: number;
  annotationLength: number;
  tempUpdateAnnotation: (updatedAnnotationStep: any) => void;
  getPreviousAnnotationStep: () => void;
  getNextAnnotationStep: () => void;
  currentAction: any;
  setCurrentAction: Dispatch<SetStateAction<any>>;
  saveCoordinates: (coordinates: any, annotoriousFormat: any) => void;
  updateCoordinates: (coordinates: any, annotoriousFormat: any) => void;
  currentAnnotation: any;
  annotationExists: boolean;
  currentTime: number;
  setCurrentTime: Dispatch<SetStateAction<number>>;
  originalAnnotation: any;
}

export const AnnotationContext = createContext<AnnotationContextInterface>({
  annotation: null,
  getAnnotation: () => undefined,
  updateAnnotation: () => undefined,
  currentAnnotationStep: null,
  currentAnnotationStepIndex: -1,
  annotationLength: 0,
  tempUpdateAnnotation: () => undefined,
  getPreviousAnnotationStep: () => undefined,
  getNextAnnotationStep: () => undefined,
  currentAction: null,
  setCurrentAction: () => undefined,
  saveCoordinates: () => undefined,
  updateCoordinates: () => undefined,
  currentAnnotation: null,
  annotationExists: false,
  currentTime: 0,
  setCurrentTime: () => undefined,
  originalAnnotation: null,
});

export const AnnotationProvider = ({ children }: any) => {
  const [annotation, setAnnotation] = useState<any>();
  const [annotationExists, setAnnotationExists] = useState(false);
  const [annotationLength, setAnnotationLength] = useState(0);
  const [currentAnnotationStep, setCurrentAnnotationStep] = useState<any>();
  const [currentAnnotationStepIndex, setCurrentAnnotationStepIndex] = useState(0);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentAnnotation, setCurrentAnnotation] = useState<any>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [originalAnnotation, setOriginalAnnotation] = useState<any>(null);

  useEffect(() => {
    if (!annotation) return;
    const newAnnotationStep = { ...annotation.annotation_tree.steps[currentAnnotationStepIndex] };
    if (newAnnotationStep.template.length === 0) {
      newAnnotationStep.completed = true;
    }
    setCurrentAnnotationStep(newAnnotationStep);
    setAnnotationLength(annotation.annotation_tree.steps.length);
  }, [annotation]);

  useEffect(() => {
    if (!annotation) return;
    console.log('currentAnnotationStepIndex changed: ' + currentAnnotationStepIndex);
    console.log('annotation: ' + annotation);
    const newAnnotationStep = { ...annotation.annotation_tree.steps[currentAnnotationStepIndex] };
    if (newAnnotationStep.template.length === 0) {
      newAnnotationStep.completed = true;
    }
    setCurrentAnnotationStep(newAnnotationStep);
  }, [currentAnnotationStepIndex]);

  const saveCoordinates = (coordinates: any, annotoriousFormat: any) => {
    setCurrentAnnotation({ coordinates, annotoriousFormat });
    setCurrentAction(null);
  };

  const updateCoordinates = (coordinates: any, annotoriousFormat: any) => {
    setCurrentAnnotationStep((currentAnnotationStep: any) => {
      if (currentAnnotationStep.type !== 'annotation') return currentAnnotationStep;
      const templateIndex = currentAnnotationStep.template.findIndex((temp: any) => {
        return temp.annotoriousFormat?.id === annotoriousFormat.id;
      });
      if (templateIndex === -1) return currentAnnotationStep;
      const updatedAnnotationStep = { ...currentAnnotationStep };
      updatedAnnotationStep.template[templateIndex].coordinates = coordinates;
      updatedAnnotationStep.template[templateIndex].annotoriousFormat = annotoriousFormat;
      return updatedAnnotationStep;
    });
  };

  const getAnnotation = async (stageId: number, sequenceId: number, alertCallback: (message: string) => void) => {
    try {
      const res = await axios.get(`/stage/${stageId}/sequence/${sequenceId}/annotation`);
      setAnnotation(res.data);
      setAnnotationExists(true);
      setOriginalAnnotation(res.data);
      setAnnotationLength(res.data.annotation_tree.steps.length);
      setCurrentAnnotationStepIndex(1);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          const annotationTemplate = {
            annotation_tree: JSON.parse(JSON.stringify(parentTemplate)),
            time: 0,
            sequence_id: sequenceId,
            stage_id: stageId,
          };          
          setAnnotation(annotationTemplate);
          setAnnotationExists(false);
          setOriginalAnnotation(annotationTemplate);
          setAnnotationLength(annotationTemplate.annotation_tree.steps.length);
          setCurrentAnnotationStepIndex(0);          
          return;
        }
      }
      alertCallback('An error occured while fetching annotation');
      console.error(err);
    }
  };

  const tempUpdateAnnotation = (updatedAnnotationStep: any) => {
    const tempAnnotation = { ...annotation };
    tempAnnotation.annotation_tree.steps[currentAnnotationStepIndex] = updatedAnnotationStep;
    if (updatedAnnotationStep.type === 'questionnaire') {
      tempAnnotation.annotation_tree.steps[currentAnnotationStepIndex + 1] = generatePointAnnotationTemplate(
        tempAnnotation.annotation_tree.steps
      );
    }

    setAnnotation(tempAnnotation);
  };

  const updateAnnotation = async (alertCallback: (message: string) => void) => {
    try {
      if (annotationExists) {
        await axios.put(
          `/annotation/${annotation.id}`,
          JSON.stringify({
            stage_id: annotation.stage_id,
            sequence_id: annotation.sequence_id,
            time: currentTime,
            annotation_tree: annotation.annotation_tree,
          })
        );
      } else {
        await axios.post('/annotation', JSON.stringify({ ...annotation, time: currentTime }));
        //setAnnotationExists(true);
      }
    } catch (err) {
      console.error(err);
      alertCallback('An error occured while saving annotation');
    }
  };

  const getNextAnnotationStep = () => {
    setCurrentAnnotationStepIndex(currentAnnotationStepIndex + 1);
  };

  const getPreviousAnnotationStep = () => {
    // reset current annotation step
    const newAnnotationStep = { ...currentAnnotationStep };
    newAnnotationStep.completed = false;
    for (const category of newAnnotationStep.template) {
      category.coordinates = [];
      category.annotoriousFormat = null;
      category.selected = null;
      category.completed = false;
    }
    tempUpdateAnnotation(newAnnotationStep);
    setCurrentAnnotationStepIndex(currentAnnotationStepIndex - 1);
  };

  const value = {
    annotation,
    getAnnotation,
    updateAnnotation,
    annotationLength,
    currentAnnotationStep,
    currentAnnotationStepIndex,
    tempUpdateAnnotation,
    getPreviousAnnotationStep,
    getNextAnnotationStep,
    currentAction,
    setCurrentAction,
    saveCoordinates,
    currentAnnotation,
    annotationExists,
    currentTime,
    setCurrentTime,
    originalAnnotation,
    updateCoordinates,
  };

  return <AnnotationContext.Provider value={value}>{children}</AnnotationContext.Provider>;
};
