import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Navigate, Route, Routes } from 'react-router-dom';
import AlertSnackbar from './components/Common/AlertSnackbar';
import InfoSnackbar from './components/Common/InfoSnackbar';
import Loading from './components/Common/Loading';
import PrivateRouteWrapper from './components/Common/PrivateRouteWrapper';
import Images from './routes/Images';
import Login from './routes/Login';
import Project from './routes/Project';
import ProjectDashboard from './routes/ProjectDashboard';
import ProjectsDrawer from './routes/ProjectsDrawer';
import ProjectsGallery from './routes/ProjectsGallery';
import Sequence from './routes/Sequence';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#008bd0',
    },
    secondary: {
      main: '#ff9000',
    },
    info: {
      main: '#ff9100',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#2b2b2b',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#6b6b6b',
            minHeight: 24,
            border: '3px solid #2b2b2b',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#959595',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#2b2b2b',
          },
        },
      },
    },
  },
});

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertSnackbar />
        <InfoSnackbar />
        <Loading />
        <Routes>
          <Route element={<PrivateRouteWrapper />}>
            <Route path="/projects" element={<ProjectsDrawer />}>
              <Route index element={<ProjectsGallery />} />
              <Route path=":id" element={<Project />} />
              <Route path=":id/dashboard" element={<ProjectDashboard />} />
            </Route>
            <Route path="projects/:id/stages/:stageId/sequences/:sequenceId/images" element={<Sequence />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/projects" replace />} />
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
