import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import AccordionWrapper from '../Common/AccordionWrapper';
import AlertDialog from '../Common/AlertDialog';
import { AuthContext } from '../../context/AuthContext';
import SequenceInfoBox from './SequenceInfoBox';
const QuestionnaireGrid = ({ level, updateAnnotation, questionnaireType, getAssociatedPolygonsWithLevel }: any) => {
  const { isAnnotator } = useContext(AuthContext);
  const { label, columns, rows, completed } = level;
  const [isExpanded, setIsExpanded] = useState(true);
  const [latestUpdate, setLatestUpdate] = useState<any>(null);
  const [lockUpdateAnnotation, setLockUpdateAnnotation] = useState<boolean>(false);
  const [openLockAlertDialog, setOpenLockAlertDialog] = useState(false);
  const [alertDialogText, setAlertDialogText] = useState<string>('');

  useEffect(() => {
    if (completed) handleAccordionChange();
  }, [completed]);

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!latestUpdate) return;
    if (questionnaireType === 'user_annotation') return;
    if (openLockAlertDialog) return;
    if (lockUpdateAnnotation) return;

    const { updateRow, column } = latestUpdate;
    handleLatestUpdate(updateRow, column);
  }, [latestUpdate, openLockAlertDialog, lockUpdateAnnotation]);

  const handleLatestUpdate = (updateRow: any, column: any) => {
    const newLevel = { ...level };
    const rowIndex = level.rows.findIndex((row: any) => row.id === updateRow.id);
    newLevel.rows[rowIndex].selected = column.value;
    newLevel.rows[rowIndex].completed = true;
    updateAnnotation(newLevel);
  };

  const handleSelectOption = (updateRow: any, column: any) => {
    const newLatestUpdate = { updateRow, column };
    if (questionnaireType === 'ground_truth_annotation') {
      if (column.value === 0 && updateRow.selected !== null) {
        const associatedPolygons = getAssociatedPolygonsWithLevel(updateRow);
        setLatestUpdate(newLatestUpdate);

        if (associatedPolygons === undefined) {
          setOpenLockAlertDialog(false);
          setLockUpdateAnnotation(false);
        } else {
          setAlertDialogText(
            'Selecting this option will permanentely delete the associated polygon. Please click Confirm to proceed.'
          );
          setOpenLockAlertDialog(true);
          setLockUpdateAnnotation(true);
        }
      } else {
        handleLatestUpdate(updateRow, column);
      }
    } else {
      handleLatestUpdate(updateRow, column);
    }
  };

  const handleCloseLockAlertDialog = () => {
    setLatestUpdate(null);
    setOpenLockAlertDialog(false);
  };

  const handleAgreeClick = () => {
    const newLevel = { ...level };
    for (let i = 0; i < newLevel.rows.length; i++) {
      newLevel.rows[i].completed = true;
    }
    updateAnnotation(newLevel);
  };

  return (
    <>
      {level.display_info ? <SequenceInfoBox /> : null}
      <AccordionWrapper title={label} isExpanded={isExpanded} handleChange={handleAccordionChange}>
        <TableContainer>
          <Table size="small" sx={{ maxWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>{/* {label} */}</TableCell>
                {columns.map((column: any) => (
                  <TableCell key={column.value}>
                    <Typography variant="body2" sx={{ fontSize: '0.7rem', padding: 0 }}>
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" sx={{ fontSize: '0.7rem' }}>
                    {row.label}
                  </TableCell>
                  {columns.map((column: any) => (
                    <TableCell
                      key={column.value}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 14,
                        },
                        paddingLeft: 1,
                        paddingY: 0,
                        // margin: 2,
                      }}
                    >
                      <Radio
                        color={row.completed && questionnaireType === 'ground_truth_annotation' ? 'success' : 'primary'}
                        size="small"
                        checked={column.value === row.selected}
                        value={column.value}
                        onClick={() => handleSelectOption(row, column)}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isAnnotator() ? (
          <Box
            m={1}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button color="success" onClick={handleAgreeClick}>
              Confirm
            </Button>
          </Box>
        ) : null}
      </AccordionWrapper>
      <AlertDialog
        open={openLockAlertDialog}
        handleClose={handleCloseLockAlertDialog}
        action={() => {
          setOpenLockAlertDialog(false);
          setLockUpdateAnnotation(false);
        }}
        title="Warning"
        text={alertDialogText}
      />
    </>
  );
};

export default QuestionnaireGrid;
