import { AddCircleOutline, Help, Logout, ViewList } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// import M3i from '../assets/M3i logo plain.png';
import Zeiss from '../assets/Zeiss_logo.svg';
import ProjectAdd from '../components/Projects/ProjectAdd';
import ProjectAddCases from '../components/Projects/ProjectAddCases';
import ProjectAccess from '../components/Projects/ProjectAccess';
import ProjectsDialog from '../components/Projects/ProjectsDialog';
import ProfileDialog from './ProfileDialog';
import { AuthContext } from '../context/AuthContext';
import { ProjectsContext } from '../context/ProjectsContext';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
const ProjectsDrawer = () => {
  const { setOpenAddDialog, setOpenAllDialog, openAllDialog, getProjects } = useContext(ProjectsContext);
  const { user, logout } = useContext(AuthContext);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const { username, role } = user;

  useEffect(() => {
    getProjects();
  }, []);

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleClickOpenAllDialog = () => {
    setOpenAllDialog(true);
  };

  const handleCloseProfileDialog = () => {
    setOpenProfileDialog(false);
  };
  const handleCloseAllDialog = () => {
    setOpenAllDialog(false);
  };

  const handleLogout = () => {
    logout();
  };

  const handleOpenUserManagement = () => {
    window.open(`${process.env.REACT_APP_API_BASE_URL}/admin`);
  };

  const handleOpenHelp = () => {
    //window.open('https://drive.google.com/file/d/1yxSaCosGJ5XJ4xFiOD3FK5Ro2vlcZmJg/view?usp=sharing');
    //window.open('https://drive.google.com/file/d/1629XsWnjkN2o0YK-A3LHIE0b64HBGic4/view?usp=share_link');
    //window.open('https://docs.google.com/document/d/1HLanBXexjsEwllm5YYXX1H20sq6YbNM8KNpyFkX7Wbw/edit?usp=sharing');
    //window.open('https://drive.google.com/file/d/16ZhG6_2tpGKz_IgdbBUJMC5pZuxgMc1D/view?usp=sharing');
    //window.open('https://drive.google.com/file/d/1Ozq3s8gPEiN3nw7k1vpzpcRzk9ZkdSuH/view?usp=sharing');
    window.open('https://drive.google.com/file/d/153COukMu-t1AnBEgJ7uP1Ui7la18Ajv5/view?usp=sharing');
  };
  const handleProfileClick = () => {
    setOpenProfileDialog(true);
  };

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Grid
        item
        xs={3}
        sx={{
          boxShadow: '10px 0px 10px 0px rgba(0,0,0,0.6)',
          height: '100vh',
          zIndex: 5,
        }}
      >
        {/* <Box m={5}>
          <img src={M3i} width="90%" />
        </Box> */}
        <Box ml={5} sx={{ width: '100%' }}>
          <img src={Zeiss} style={{ display: 'block', width: '40%' }} />
        </Box>
        <Box
          sx={{
            padding: 5,
            top: '30%',
            position: 'absolute',
          }}
        >
          <Typography variant="h3">Welcome!</Typography>
          <Box>
            <Button size="small" startIcon={<ViewList />} id="load-results" onClick={handleClickOpenAllDialog}>
              Show all projects
            </Button>
          </Box>
          <Box>
            <Button size="small" startIcon={<Help />} id="create-project" color="primary" onClick={handleOpenHelp}>
              Help
            </Button>
          </Box>
          <Divider />
          {role === 'admin' && (
            <>
              <Box my={1}>
                <Typography variant="subtitle2" color="GrayText">
                  Administration
                </Typography>
              </Box>

              <Box>
                <Button
                  size="small"
                  startIcon={<AddCircleOutline />}
                  onClick={handleClickOpenAddDialog}
                  id="create-project"
                  color="secondary"
                >
                  Create a new project
                </Button>
              </Box>
              <Box>
                <Button
                  size="small"
                  startIcon={<AddCircleOutline />}
                  id="admin-panel"
                  color="secondary"
                  onClick={handleOpenUserManagement}
                >
                  User management
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 80,
            left: 50,
            display: 'flex',
          }}
        >
          <Box>
            <Typography>
              Logged in as <b>{username}</b>
            </Typography>
            {/* <Button size="small" startIcon={<Key />} id="admin-panel" color="primary">
              Reset Password
            </Button> */}
            <Box>
              <Button size="small" startIcon={<Logout />} id="admin-panel" color="primary" onClick={handleLogout}>
                Logout
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                startIcon={<AccountBoxIcon />}
                id="admin-panel"
                color="primary"
                onClick={handleProfileClick}
              >
                Profile
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          height: '100vh',
          zIndex: 2,
          overflowY: 'auto',
        }}
      >
        <Outlet />
      </Grid>
      <ProjectAdd />
      <ProjectAddCases />
      <ProjectAccess />
      <ProjectsDialog open={openAllDialog} handleClose={handleCloseAllDialog} />
      <ProfileDialog open={openProfileDialog} handleClose={handleCloseProfileDialog} />
    </Grid>
  );
};

export default ProjectsDrawer;
