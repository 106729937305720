import { CheckCircle } from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Project from '../../routes/Project';
import AlertDialog from '../Common/AlertDialog';
import { SequencesContext } from '../../context/SequencesContext';
import { AuthContext } from '../../context/AuthContext';

const SequenceCard = ({ sequence, stage, index }: any) => {
  const navigate = useNavigate();
  const { deleteCases } = useContext(CommonContext);
  const { deleteSequence } = useContext(SequencesContext);
  const [openLockAlertDialog, setOpenLockAlertDialog] = useState(false);
  const [lockAlertDialogText, setLockAlertDialogText] = useState('');
  const { isAnnotator, isAdmin, isAnalyst } = useContext(AuthContext);

  const handleDeleteClick = () => {
    setOpenLockAlertDialog(true);
    setLockAlertDialogText('Please confirm you want to delete this case.');
  };

  const goToCase = () => {
    if (deleteCases) return;
    navigate(`stages/${stage.id}/sequences/${sequence.id}/images`);
  };

  const handleCloseLockAlertDialog = () => {
    setOpenLockAlertDialog(false);
  };

  const deleteCase = () => {
    deleteSequence(sequence.id, (message: string) => {
      return null;
    });
    handleCloseLockAlertDialog();
  };

  return (
    <Card
      onClick={goToCase}
      sx={{
        width: '100%',
        border: '1px solid',
        borderColor: sequence.completed ? 'primary.main' : 'black',
        '&:hover': {
          border: '1px solid',
          borderColor: 'primary.main',
          transform: 'scale(1.005)',
        },
      }}
    >
      <CardActionArea>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="body1" component="h6">
                {
                  isAnnotator() || isAdmin() || isAnalyst() ? 'Case ' +sequence.id : 'Case ' + (index + 1)
                  
                }
              </Typography>
            </Box>
            <Box>
              {sequence.completed && <CheckCircle color="primary" />}
              {deleteCases && <DeleteIcon onClick={handleDeleteClick} color="secondary" />}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
      <AlertDialog
        open={openLockAlertDialog}
        handleClose={handleCloseLockAlertDialog}
        action={deleteCase}
        title="Alert"
        text={lockAlertDialogText}
      />
    </Card>
  );
};

export default SequenceCard;
