import { Box, Grid } from '@mui/material';
import { useContext } from 'react';
import AnnotationContainer from '../components/Viewer/AnnotationContainer';
import GroundTruthContainer from '../components/Viewer/GroundTruthContainer';
import Header from '../components/Viewer/Header';
import Viewer from '../components/Viewer/Viewer';
import { AnnotationContext } from '../context/AnnotationContext';
import { GroundTruthAnnotationContext } from '../context/GroundTruthAnnotationContext';
import { AuthContext } from '../context/AuthContext';

const Sequence = () => {
  const { isAnnotator } = useContext(AuthContext);
  const { currentAction, saveCoordinates, currentAnnotationStep, annotationExists, updateCoordinates } =
    useContext(AnnotationContext);
  const {
    currentGroundTruthAction,
    currentGroundTruthAnnotationStep,
    groundTruthAnnotationExists,
    updateGroundTruthCoordinates,
    saveCoordinatesGroundTruth,
    deleteGroundTruthCoordinates,
    handleUndoEventGroundTruthAnnotation,
    undoAvailable,
  } = useContext(GroundTruthAnnotationContext);

  return (
    <Box sx={currentAction ? { cursor: 'crosshair !important' } : null}>
      <Header />
      <Box sx={{ mt: 8 }}>
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              {isAnnotator() ? (
                <Viewer
                  useViews={currentGroundTruthAnnotationStep?.id == 1}
                  annotationType={'ground_truth_annotation'}
                  currentAction={currentGroundTruthAction}
                  saveCoordinates={saveCoordinatesGroundTruth}
                  currentAnnotationStep={currentGroundTruthAnnotationStep}
                  annotationExists={groundTruthAnnotationExists}
                  updateCoordinates={updateGroundTruthCoordinates}
                  deleteCoordinates={deleteGroundTruthCoordinates}
                  handleUndoEvent={handleUndoEventGroundTruthAnnotation}
                  undoAvailable={undoAvailable}
                />
              ) : (
                <Viewer
                  useViews={currentAnnotationStep?.id == 1}
                  annotationType={'user_annotation'}
                  currentAction={currentAction}
                  saveCoordinates={saveCoordinates}
                  currentAnnotationStep={currentAnnotationStep}
                  annotationExists={annotationExists}
                  updateCoordinates={updateCoordinates}
                  deleteCoordinates={null}
                  handleUndoEvent={null}
                  undoAvailable={false}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {isAnnotator() ? <GroundTruthContainer /> : <AnnotationContainer />}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Sequence;
