import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useContext } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { ProjectsContext } from '../../context/ProjectsContext';
import ProjectsTable from './ProjectsTable';

const ProjectsDialog = ({ open, handleClose }: any) => {
  const { setAlertMessage } = useContext(CommonContext);
  const { projects, removeProjects } = useContext(ProjectsContext);

  const exportProjects = async (ids: number[]) => {
    // const selected = projects.filter((cs) => ids.includes(cs.id));
    // const res: any = await exportProjectsToCoco(selected);
    setAlertMessage('Implement export');
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth={true}>
      <DialogContent>
        <ProjectsTable
          rows={projects}
          removeProjects={removeProjects}
          exportProjects={exportProjects}
          setAlertMessage={setAlertMessage}
          handleClose={handleClose}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectsDialog;
